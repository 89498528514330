export enum Form8974SideDrawerTypes {
  TIER1_GUSTO = 'tier1Gusto',
  TIER1_RIPPLING = 'tier1Rippling',
  TIER1_JUSTWORKS = 'tier1Justworks',
  TIER2 = 'tier2',
}

export const QualificationSteps = [
  'Connect payroll',
  'Payroll details',
  'R&D credit overview',
  'R&D activities',
  'R&D 4-part test',
];

export enum QualificationStepsIndex {
  CONNECT_PAYROLL = 1,
  PAYROLL_DETAILS = 2,
  OVERVIEW = 3,
  RD_ACTIVITIES = 4,
  RD_4_PART_TEST = 5,
}

export const HidePayrollQualificationSteps = [
  'Payroll details',
  'R&D credit overview',
  'R&D activities',
  'R&D 4-part test',
];

export enum HidePayrollQualificationStepsIndex {
  PAYROLL_DETAILS = 1,
  OVERVIEW = 2,
  RD_ACTIVITIES = 3,
  RD_4_PART_TEST = 4,
}

export enum CarryoverAndAdjustStatusEnum {
  AVAILABLE = 'available',
  REDEEMED = 'redeemed',
  ZERO_BALANCE = 'zero_balance',
  NOT_FIRST_MONTH_OF_QUARTER = 'not_first_month_of_quarter',
  NOT_ELIGIBLE = 'not_eligible',
  /**
   * @deprecated Use TAXES_FILED_TOO_RECENTLY instead, which is a more general description of
   * a filing date-related issue with the carryover status.
   */
  TAXES_FILED_PREV_QUARTER = 'taxes_filed_prev_quarter',
  TAXES_FILED_TOO_RECENTLY = 'taxes_filed_too_recently',
  PROGRAM_LEDGER_SETUP_REQUIRED = 'program_ledger_setup_required',
  NOT_USING_TIER3_PAYROLL_SYSTEM = 'not_using_tier3_payroll_system',
  UNKNOWN = 'unknown',
  MISSED_FILING = 'missed_filing',
  PROJECTED_MISSED_FILING = 'projected_missed_filing',
  NO_MISSING_FILINGS = 'no_missing_filings',
}

/**
 * The set of carryover statuses indicating that a customer should NOT be able to
 * attempt to redeem credits in the tier 3 side drawer.
 */
export const NegativeCarryoverStatuses = [
  CarryoverAndAdjustStatusEnum.NOT_ELIGIBLE,
  CarryoverAndAdjustStatusEnum.ZERO_BALANCE,
  CarryoverAndAdjustStatusEnum.TAXES_FILED_TOO_RECENTLY,
  CarryoverAndAdjustStatusEnum.NOT_USING_TIER3_PAYROLL_SYSTEM,
  CarryoverAndAdjustStatusEnum.TAXES_FILED_PREV_QUARTER,
  CarryoverAndAdjustStatusEnum.NOT_FIRST_MONTH_OF_QUARTER,
  CarryoverAndAdjustStatusEnum.REDEEMED,
];

export enum ProgramAdvanceCreditOptOutReasonEnum {
  NOT_ELIGIBLE = 'not_eligible',
  OPTED_OUT = 'opted_out',
}

export enum RdProjectCategoryEnum {
  COMPUTER_SOFTWARE = 'computer_software',
  PHYSICAL_PRODUCT = 'physical_product',
  PROCESS = 'process',
  NOT_ANSWERED = 'not_answered',
}

export enum ProgramLocationEnum {
  FEDERAL = 'federal',
  CALIFORNIA = 'california',
  ARIZONA = 'arizona',
  GEORGIA = 'georgia',
  MASSACHUSETTS = 'massachusetts',
}

export enum ProgramDisqualifiedReasonEnum {
  MINI_QUAL = 'mini_qual',
  AUTO_QUAL_FOUR_PART_TEST = 'auto_qual_four_part_test',
  ACCOUNTANT_AUDIT = 'accountant_audit',
  OPS_REVIEW = 'ops_review',
}

export enum ERCStepsIndex {
  QUALIFY_FOR_ERC = 1,
  UPLOAD_TAX_RETURN = 2,
  CONNECT_TO_PAYROLL = 3,
  UPLOAD_PAYROLL_RETURNS = 4,
  CONFIRM_ADDRESS = 5,
  PAYROLL_DETAILS = 6,
  CREDIT_ESTIMATE = 7,
  ERC_ORDER_FORM = 8,
}

export enum ERCQualificationStatusEnum {
  DQ_COMPANY_CLAIMED_ERC = 'dq-claimed-erc',
  DQ_COMPANY_FOUNDED_PRIOR_2020 = 'dq-founded-prior-2020',
  DQ_COMPANY_FOUNDED_AFTER_2021 = 'dq-founded-after-2021',
  NOTIFICATION_COMPANY_TOOK_OUTSIDE_CREDIT = 'notification-company-took-outside-credit',
  NOTIFICATION_RELATED_ENTITY_FOUNDED_IN_2021 = 'notification-related-entity-founded-in-2021',
  QUALIFIED_FOUNDED_IN_2021 = 'qualified-founded-in-2021',
  QUALIFIED_FOUNDED_IN_2020 = 'qualified-founded-in-2020',
  QUALIFIED_FOUNDED_IN_2019 = 'qualified-founded-in-2019',
  QUALIFIED_FOUNDED_BEFORE_2019 = 'qualified-founded-before-2019',
  QUALIFIED_MAYBE_QUALIFIED = 'qualified-maybe-qualified',
  BILLING_SUMMARY = 'billing-summary',
}

export enum RetirementPlanStepsIndex {
  BUSINESS_DETAILS = 1,
  PAYROLL_CONNECTION = 2,
  NHCE_DETAILS = 3,
  CREDIT_ESTIMATE = 4,
  BILLING_SUMMARY = 5,
}

export enum QualificationModalStatusEnum {
  RETIREMENT_DQ_INELIGIBLE_1 = 'retirement-dq-ineligible-1',
  RETIREMENT_DQ_INELIGIBLE_2 = 'retirement-dq-ineligible-2',
  RETIREMENT_ELIGIBLE_1 = 'retirement-eligible-1',
  BILLING_SUMMARY = 'billing-summary',
  DISABLED_ACCESS_DQ = 'disabled-access-dq-ineligible',
  HEALTHCARE_DQ = 'small-business-healthcare-dq-ineligible',
  UNIFIED_RETIREMENT_DQ = 'unified-retirement-dq-ineligible',
  UNIFIED_RD_DQ = 'unified-rd-dq-ineligible',
  BENEFICIAL_OWNERSHIP_DQ = 'beneficial-ownership-information-report-dq-ineligible',
}

export enum AssessmentSurveyStepsIndex {
  CONNECT_PAYROLL = 1,
  BUSINESS_DETAILS = 2,
  RETIREMENT_PLAN = 3,
  SMALL_BUSINESS_HEALTHCARE = 4,
  RD_COMPANY_DETAILS = 5,
  RD_SUPPLIES_SERVICES = 6,
  RD_EMPLOYEES = 7,
  DISABLED_ACCESS = 8,
  REVIEW = 9,
  PAYMENT = 10,
}

export const DocumentTypes = [
  'FORM_8974',
  'FORM_8974_DRAFT',
  'FORM_6765',
  'FORM_6765_DELIVERABLES',
  'PAYROLL',
  'FORM_8879',
  'CLOUD_COMPUTING',
  'R&D_SUPPLIES',
  'ENTITY_CHANGE_INFORMATION',
  'FORM_941',
  'FORM_941_X',
  'OTHER',
  'CALIFORNIA_CORPORATE_INCOME_TAX_RETURN',
  'GEORGIA_CORPORATE_INCOME_RETURN',
  'MASSACHUSETTS_CORPORATE_INCOME_RETURN',
  'ARIZONA_CORPORATE_INCOME_TAX_RETURN',
  'GRANT',
  'ACQUISITION_INFORMATION',
  'TIME_TRACKING_DATA',
  'FEDERAL_CORPORATE_INCOME_TAX_RETURN',
  'CA_FORM_3523',
  'CA_FORM_3523_DELIVERABLES',
  'FD_FORM_8881',
  'FD_FORM_8881_2023',
  'FD_FORM_8826',
  'FD_FORM_8941',
  'AZ_FORM_308',
  'AZ_FORM_308_DELIVERABLES',
  'GA_FORM_48_7_40_12',
  'GA_FORM_48_7_40_12_DELIVERABLES',
  'MA_SCHEDULE_RC',
  'MA_SCHEDULE_RC_DELIVERABLES',
] as const;
export type DocumentType = (typeof DocumentTypes)[number];
