import hash from 'object-hash';
import Stripe from 'stripe';
import { datadogLogs } from '@datadog/browser-logs';
import {
  CustomerTransferRequest,
  CustomerTransferResponse,
} from '@mainstreet/client-models/financial/accounting/yieldAccountLedger';
import { FileHandle } from 'component-library';
import { StripePaymentMethod } from 'components/payments/v2/stripe/types';
import { AddressEntity, AddressTypeEnum } from 'entities/AddressEntity';
import {
  AcceptedByData,
  AccountCreationRequest,
  AccountCreationSteps,
  BeneficiariesResponse,
  BusinessApplicationData,
  BusinessResponse,
  ClientUpdateProgramRequest,
  CmsFaqData,
  CmsQualificationQuestionDetails,
  CmsQuestionGroupData,
  Company8974Finalization,
  CompanyAddress,
  CompanyChatBotSettings,
  CompanyData,
  CompanyInfo,
  CreateCompanySelfSignupRequest,
  CreateEmploymentRecordRequest,
  CreateProgramRequest,
  CreateRdProject,
  CreditCategory,
  CreditEstimateSummary,
  DeleteRdProjectRequest,
  Document,
  DocumentForUpload,
  EmployeeForEmployeeTable,
  EmploymentRecordData,
  EmploymentRecordDataWithWorkDoneData,
  ERCCreditEstimateResponse,
  FilingStatusQuarter,
  FinancialInformation,
  FinchPayrollProvider,
  FormData6765,
  GenerateIdempotencyIdRequest,
  GetKycApplicationStatusResponse,
  GetOrgAdministrators,
  IdempotencyIdRequestSchema,
  ImportType,
  IrsTestPartFour,
  IssuedSurvey,
  MagicMoneyDataOverrides,
  ModernTreasuryExpectedPayment,
  MSApiResponse,
  MSClientResponse,
  OrderForm,
  PartnerInfo,
  PatchCompanyMiscRequest,
  PersonApplicationData,
  ProgramCreditEstimate,
  ProgramCreditEstimateResponse,
  ProgramData,
  ProgramRdQA,
  ProjectData,
  PromissoryNote,
  QuarterDescription,
  RdActivities,
  RdProject,
  RdVendorExpense,
  RdVendorExpenseWithAccessToken,
  RetirementEstimateResponse,
  SignPromissoryNoteReqBody,
  SubmitAccreditationResponse,
  SubmitBusinessDataRequest,
  SubmitKycBeneficiariesData,
  SupportingDocumentData,
  SurveyResponse,
  TMAccount,
  TMStepStatus,
  TransactionsData,
  UpdateCompanyDetailsInfoRequest,
  UpdateCompanyManualPayrollEstimateRequest,
  UpdateCompanyOperationsInfoRequest,
  UpdateCompanyPayrollSystemRequest,
  UpdateCompanyQBDataRequest,
  UpdateCompanyRequest,
  UpdateCompanySignupStageRequest,
  UpdateCompanySourcingDataRequest,
  UpdateCompanyTaxInfoRequest,
  UpdateCompanyWithGapPayrollRequest,
  UpdateCreditEstimate,
  UpdateEmployeeDetailsInfoRequest,
  UpdateMissingInformationEmploymentRecordRequest,
  UpdatePasswordRequest,
  UpdateProgramRequest,
  UpdateProgramsRequest,
  UpdateProgramStageRequest,
  UpdateProgramSubStageRequest,
  UpdateProgramTaxFilingDateRequest,
  UpdateProjectRequest,
  UpdateRdProjectRequest,
  UpdateWorkDoneRequest,
  UploadCompanyDocumentsRequest,
  ValidatedToken,
  WorkDoneData,
} from 'lib/interfaces';
import { ValidateEmail } from 'lib/validation';
import { BalanceInformation } from 'pages/dashboard/highYieldSavings/components/AccountBalanceCard';
import { Delete, Get, Post, Put, Upload } from 'services/serverGenerics';
import {
  ACCEPTANCE,
  ACCOUNTING,
  ALL,
  API,
  APIV1,
  APIV2,
  AT_MS_DOMAIN,
  AttestationEventType,
  AUTH,
  AutoqualificationStatusEnum,
  BILLING,
  CALCULATE_ESTIMATE,
  CarryoverAndAdjustStatusEnum,
  CHATBOT_API,
  CHATBOT_SETTINGS,
  CHATBOT_TOGGLE_AI,
  COMPANIES,
  COMPANY,
  COMPANY_ACCESS_TOKEN,
  CompanyAccessToken,
  CONFIG,
  CUSTOMER_IO,
  DASHBOARD,
  DashboardData,
  DOCUMENTS,
  EMAIL_LINK,
  EMPLOYEE,
  EMPLOYEE_ACCESS_LINK,
  EmployeeTokenPurposeEnum,
  EMPLOYMENT_RECORD,
  EXTERNAL_SERVICE_TOKEN,
  FAQ,
  FORMS,
  GraphCmsFaqGroupIdEnum,
  IDEMPOTENCY_HEADER,
  ISSUED_SURVEY,
  IssuedSurveyStatusEnum,
  KYC,
  LEDGERS,
  LOADING_STATUS,
  LoadingStatusTypeEnum,
  OAUTH,
  ORDER,
  ORDER_FORM,
  Page,
  PAYROLL,
  PAYROLL_ACCESS_LINK,
  PayrollTierEnum,
  PLAID,
  ProductTypeEnum,
  PROGRAM,
  PROJECT,
  PROMISSORY_NOTE,
  QUALIFICATION,
  RD_PROJECTS,
  REQUAL_SURVEY,
  ROOT_8974,
  SignupStageEnum,
  SURVEY,
  SurveyNameEnum,
  TAX_CREDITS,
  TERMS,
  TransactionStateEnum,
  TREASURY_MANAGEMENT,
  WORK_DONE,
  DocumentType,
} from '../lib/constants';
import { ImportDataSchema, PayrollImportData } from '../lib/interfaces/payroll';
import { logContext } from '../logging';
import { AbstractWebClient } from './AbstractWebClient';
import { Program } from '../entities/Program';
import { EmploymentRecordWithWorkDoneData } from '../products/tax-credits/features/unified-tax-credits/assessments/employees/interfaces';

const logger = datadogLogs.createLogger('ServerClient');

const somethingWentWrong =
  'An error occurred, please try again or contact support.';

/**
 * Auth Enabled mono-client containing endpoints for most of the routes
 * on the Server.
 *
 * // TODO: break this down in to separate auth0 enabled clients as part of service sovereignty
 */
export class ServerClient extends AbstractWebClient {
  private readonly BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  static const(s: string | undefined): ServerClient {
    return new ServerClient(s);
  }

  /**
   * @param requestOptionsInput request input.
   * @deprecated call this.addSharedHeaders instead.
   */
  private wrapRequestOptions(requestOptionsInput: Record<string, any>): any {
    return this.addSharedHeaders(requestOptionsInput);
  }

  // TODO: Once /ops is nx-ified, import the version under /lib.
  GenerateIdempotencyId = (req: GenerateIdempotencyIdRequest): string => {
    const parseRes = IdempotencyIdRequestSchema.safeParse(req);
    if (!parseRes.success) {
      return '';
    }
    const parsedReq: GenerateIdempotencyIdRequest = parseRes.data;
    const windowStart =
      parsedReq.timestamp - (parsedReq.timestamp % parsedReq.window);
    parsedReq.timestamp = windowStart;
    return hash(parsedReq);
  };

  PayrollImportStatus = async (
    companyId: number,
    taxYear: number,
  ): Promise<PayrollImportData | undefined> => {
    const { data, errorMsg } = await Get<PayrollImportData>(
      `/${APIV1}/payroll-summary/${companyId}/import-status/${taxYear}`,
      [200],
      this.wrapRequestOptions({}),
    );

    if (data) {
      const parsed = ImportDataSchema.safeParse(data);
      if (parsed.success) {
        return parsed.data;
      } else if (parsed.error || errorMsg) {
        logger.error(
          `Error parsing API response. Error message: ${errorMsg}`,
          logContext({
            company: { id: companyId },
            error: parsed.error,
          }),
        );
      }
    }
  };

  CreateCompanyAndLoginSelfSignup = async (
    req: CreateCompanySelfSignupRequest,
  ): Promise<MSClientResponse<{ company: CompanyData }>> => {
    return Post<{ company: CompanyData }>(
      `/${APIV1}/${AUTH}/signup`,
      [201],
      JSON.stringify(req),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  MoveCompanyToNextOnboardingStep = async (
    nextStep: SignupStageEnum,
  ): Promise<MSClientResponse<{ company: CompanyData }>> => {
    return Post<{ company: CompanyData }>(
      `/${APIV1}/${COMPANY}/next-step`,
      [200],
      JSON.stringify({ nextStep }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  LoginWithGoogle = () => {
    window.open(`${this.BACKEND_URL}/${APIV1}/${AUTH}/google/login`, '_self');
  };

  VerifyGoogleLogin = (): Promise<
    MSClientResponse<{ company: CompanyData }>
  > => {
    return Get<{ company: CompanyData }>(
      `/${APIV1}/${AUTH}/google/success`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * The "kinda" public version of this route {@link UpdateYearFoundedPublic}
   *
   * This is "kinda" because its more limited in scope than this route
   * and only allows for updating the yearFounded
   */
  UpdateCompany = async (
    req: UpdateCompanyRequest,
  ): Promise<MSClientResponse<{ company: CompanyData; updated: boolean }>> => {
    return fetch(
      `/${API}/${COMPANY}/`,
      this.wrapRequestOptions({
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify(req),
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then(
        (
          json: MSApiResponse<{
            company: CompanyData;
            updated: boolean;
          }>,
        ) => {
          if (json.status !== 200) {
            return {
              errorMsg:
                json.status === 400 ? json.errorMsg : somethingWentWrong,
            };
          }

          return { data: json.data };
        },
      )
      .catch(() => {
        return {
          errorMsg: somethingWentWrong,
        };
      });
  };

  UpdateCompanyOperationsInfo = (
    req: UpdateCompanyOperationsInfoRequest,
  ): Promise<
    MSClientResponse<{
      company: CompanyData;
      hasQualifyingPrograms: boolean;
    }>
  > => {
    return Put<
      UpdateCompanyOperationsInfoRequest,
      { company: CompanyData; hasQualifyingPrograms: boolean }
    >(
      `/${APIV1}/${COMPANY}/operations`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  GetDisplayableCreditEstimates = (
    taxYear: number,
  ): Promise<
    MSClientResponse<{
      programCreditEstimates: ProgramCreditEstimate[];
      hasQualifyingRdPrograms: boolean;
    }>
  > => {
    return Get<{
      programCreditEstimates: ProgramCreditEstimate[];
      hasQualifyingRdPrograms: boolean;
    }>(
      `/${APIV1}/${COMPANY}/credit-estimates/${taxYear}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetDisplayableErcEstimates = (): Promise<
    MSClientResponse<{
      programCreditEstimates: ProgramCreditEstimate[];
    }>
  > => {
    return Get<{
      programCreditEstimates: ProgramCreditEstimate[];
      hasQualifyingRdPrograms: boolean;
    }>(
      `/${APIV1}/${COMPANY}/tax-credits/estimates/erc`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  OptedInSelection = (
    programId: number,
    selection: Record<ProductTypeEnum, { optedIn: boolean }>,
  ): Promise<
    MSClientResponse<{
      creditEstimateSummary: CreditEstimateSummary;
    }>
  > => {
    return Put<
      Record<ProductTypeEnum, { optedIn: boolean }>,
      {
        creditEstimateSummary: CreditEstimateSummary;
      }
    >(
      `/${APIV1}/${PROGRAM}/${programId}/magic-money-selection`,
      [200],
      selection,
      this.wrapRequestOptions({}),
    );
  };

  MagicMoneyEstimates = (
    taxYear: number,
    overrides: MagicMoneyDataOverrides,
  ): Promise<
    MSClientResponse<{
      creditEstimateSummary: CreditEstimateSummary;
    }>
  > => {
    return Put<
      MagicMoneyDataOverrides,
      {
        creditEstimateSummary: CreditEstimateSummary;
      }
    >(
      `/${APIV1}/${COMPANY}/credit-estimates/magic-money/${taxYear}`,
      [200],
      overrides,
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanyTaxInfo = (
    req: UpdateCompanyTaxInfoRequest,
  ): Promise<
    MSClientResponse<{
      company: CompanyData;
    }>
  > => {
    return Put<UpdateCompanyTaxInfoRequest, { company: CompanyData }>(
      `/${APIV1}/${COMPANY}/tax-info`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  RequestPayroll = (
    payrollSystem: string,
    token?: string,
    email?: string,
  ): Promise<MSClientResponse<{ company: CompanyData }>> => {
    return Post<{ company: CompanyData }>(
      `/${API}/${COMPANY}/${PAYROLL}/requests`,
      [201],
      JSON.stringify({
        payrollSystem,
        email,
        token,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  PostValidatePassword = (
    password: string,
  ): Promise<MSClientResponse<{ valid: boolean; errorMsg?: string }>> => {
    return fetch(
      `/${API}/${COMPANY}/validate-password`,
      this.wrapRequestOptions({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify({ password }),
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<{ valid: boolean; errorMsg?: string }>) => {
        if (json.status !== 200) {
          return {
            errorMsg: somethingWentWrong,
          };
        } else {
          return { data: json.data };
        }
      })
      .catch(() => {
        return {
          errorMsg: somethingWentWrong,
        };
      });
  };

  AddToWaitlist = (
    email: string,
    creditCategories: CreditCategory[],
    firstName: string,
    lastName: string,
    companyName: string,
  ): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${APIV1}/${COMPANY}/waitlist`,
      [201],
      JSON.stringify({
        email,
        creditCategories,
        firstName,
        lastName,
        companyName,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  UpdateCompanyPayrollSystem = (
    req: UpdateCompanyPayrollSystemRequest,
  ): Promise<
    MSClientResponse<{
      company: CompanyData;
    }>
  > => {
    return Put<UpdateCompanyPayrollSystemRequest, { company: CompanyData }>(
      `/${APIV1}/${COMPANY}/payroll-system`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  GetCompanyPayrollProvider = (
    companyId: number,
  ): Promise<
    MSClientResponse<{
      provider: string;
      payrollTier: PayrollTierEnum;
    }>
  > => {
    return Get<{ provider: string; payrollTier: PayrollTierEnum }>(
      `/${APIV1}/${COMPANY}/payrollProvider/${companyId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ========== Employment Records =========

  /**
   *  Public version of this route {@link CreateEmploymentRecordForUserCompanyPublic}
   */
  CreateEmploymentRecordForUserCompany = (
    createRequest: CreateEmploymentRecordRequest,
  ): Promise<MSClientResponse<EmploymentRecordDataWithWorkDoneData>> => {
    return Post<EmploymentRecordDataWithWorkDoneData>(
      `/${API}/${EMPLOYMENT_RECORD}/add-employee-to-user-company`,
      [201],
      JSON.stringify(createRequest),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  /**
   * Public version of this route {@link UpdateMissingEmployeeInformationPublic}
   */
  UpdateMissingEmployeeInformation = (
    recordId: number | string,
    updateRequest: UpdateMissingInformationEmploymentRecordRequest,
  ): Promise<
    MSClientResponse<{ employmentRecordData: EmploymentRecordData }>
  > => {
    return Put<
      UpdateMissingInformationEmploymentRecordRequest,
      { employmentRecordData: EmploymentRecordData }
    >(
      `/${API}/${EMPLOYMENT_RECORD}/update-missing-fields/${recordId}`,
      [200],
      updateRequest,
      this.wrapRequestOptions({}),
    );
  };

  // ========== Session ==========

  Login = (
    email: string,
    password: string,
  ): Promise<MSClientResponse<{ company: CompanyData }>> => {
    return fetch(
      `/${APIV1}/${AUTH}/login`,
      this.wrapRequestOptions({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify({
          email,
          password,
        }),
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<{ company: CompanyData }>) => {
        if (json.status !== 200) {
          return {
            errorMsg: json.errorMsg,
          };
        }

        return { data: json.data };
      })
      .catch(() => {
        return {
          errorMsg: somethingWentWrong,
        };
      });
  };

  Logout = (): Promise<boolean> => {
    return fetch(
      `/${API}/${COMPANY}/logout`,
      this.wrapRequestOptions({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<any>) => {
        if (json.status !== 200) {
          return false;
        }

        return true;
      })
      .catch(() => {
        return false;
      });
  };

  TempCreateCurrent2022Program = async (companyId: number): Promise<void> => {
    const requestOptions = this.wrapRequestOptions({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      cache: 'no-cache',
    });
    return fetch(`/${APIV2}/${COMPANIES}/tmpCreate2022Program`, requestOptions)
      .then((res) => {
        return res.status;
      })
      .then((status) => {
        if (status !== 201) {
          logger.warn(
            `Could not create first 2022 program for current company: ${companyId}`,
          );
        } else {
          logger.info(`Created a 2022 program for ${companyId}`);
        }
      });
  };

  /**
   * Public version of this api is {@link CurrentCompanyByToken}
   */
  CurrentLoggedInCompany = async (): Promise<CompanyData | null> => {
    const requestOptions = this.wrapRequestOptions({
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      cache: 'no-cache',
    });
    return fetch(`/${API}/${COMPANY}/current`, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<{ company: CompanyData | null }>) => {
        const { data, status } = json;

        if (status === 200 && data?.company) {
          return data.company;
        }

        return null;
      })
      .catch(() => {
        return null;
      });
  };

  UpdatePassword = (
    req: UpdatePasswordRequest,
  ): Promise<{ success: boolean; errorMsg?: string }> => {
    return fetch(
      `/${API}/${COMPANY}/password`,
      this.wrapRequestOptions({
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify(req),
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<{ company: CompanyData }>) => {
        if (json.status !== 200) {
          return {
            success: false,
            errorMsg: json.status === 400 ? json.errorMsg : somethingWentWrong,
          };
        }

        return {
          success: true,
        };
      })
      .catch(() => {
        return {
          success: false,
          errorMsg: somethingWentWrong,
        };
      });
  };

  // ========== Token ==========

  GenerateResetPasswordEmail = async (
    email: string,
  ): Promise<{ errorMsg?: string }> => {
    if (!email) {
      return {
        errorMsg: 'Please enter an email.',
      };
    }

    if (!ValidateEmail(email)) {
      return {
        errorMsg: 'Please enter a valid email.',
      };
    }

    return fetch(
      `/${API}/${COMPANY}/reset-password-token`,
      this.wrapRequestOptions({
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        credentials: 'include',
        cache: 'no-cache',
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<any>) => {
        if (json.status !== 200) {
          return {
            errorMsg: somethingWentWrong,
          };
        }

        return {};
      })
      .catch(() => {
        return {
          errorMsg: somethingWentWrong,
        };
      });
  };

  ValidateResetPassToken = async (
    props: ValidatedToken,
  ): Promise<MSClientResponse<{ valid: boolean }>> => {
    return fetch(
      `/${API}/${COMPANY}/validate-reset-password-token`,
      this.wrapRequestOptions({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify(props),
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<{ valid: boolean }>) => {
        if (json.status !== 200) {
          return {
            data: {
              valid: false,
            },
            errorMsg: json.status === 400 ? json.errorMsg : somethingWentWrong,
          };
        }

        return { data: json.data };
      })
      .catch(() => {
        return {
          valid: false,
          errorMsg: somethingWentWrong,
        };
      });
  };

  // ========== Dashboard ==========

  GetDashboardCredits = (): Promise<
    MSClientResponse<{
      dashboard: DashboardData;
    }>
  > => {
    return Get<{ dashboard: DashboardData }>(
      `/${API}/${DASHBOARD}/credits`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ========== Documents ==========

  /**
   * Public version of this route is {@link GetCompanyDocumentsPublic}
   */
  GetCompanyDocuments = (req: {
    source?: string;
    documentType?: string;
  }): Promise<
    MSClientResponse<{
      documents: Document[];
    }>
  > => {
    let query = '';
    if (req.source || req.documentType) {
      query += `?`;

      if (req.source) {
        query += `source=${req.source}`;

        if (req.documentType) {
          query += `&`;
        }
      }

      if (req.documentType) {
        query += `documentType=${req.documentType}`;
      }
    }

    return Get<{ documents: Document[] }>(
      `/${API}/${DOCUMENTS}/current${query}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  SetYeaPrefill = async (): Promise<MSClientResponse<void>> => {
    const { res } = await Post<void>(
      `/${API}/${COMPANY}/prefill-all-surveys`,
      [200],
      undefined,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );

    return res;
  };

  SetGeneralBusinessDetailsPrefillAnswers = async (
    taxYear: number,
  ): Promise<MSClientResponse<void>> => {
    const { res } = await Post<void>(
      `/${API}/${COMPANY}/prefill_general_business_details/tax_year/${taxYear}`,
      [200],
      undefined,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );

    return res;
  };

  SetBusinessDetailsHistoricalDataPrefill = async (
    taxYear: number,
  ): Promise<MSClientResponse<void>> => {
    const { res } = await Post<void>(
      `/${API}/${COMPANY}/prefill_general_business_details_historical_data/tax_year/${taxYear}`,
      [200],
      undefined,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );

    return res;
  };

  /**
   * Returns the documents for the given program
   * Will not return hidden docs
   */
  GetProgramDocuments = (
    programId: number,
    source: string,
    email?: string,
    token?: string,
  ): Promise<
    MSClientResponse<{
      documents: Document[];
    }>
  > => {
    const query =
      email && token
        ? `&email=${encodeURIComponent(email)}&token=${token}`
        : '';

    return Get<{ documents: Document[] }>(
      `/${API}/${DOCUMENTS}/program/${programId}?source=${
        source ? source : 'mainstreet'
      }${query}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetDocumentUrl = (
    documentId: number,
  ): Promise<
    MSClientResponse<{
      url: string;
    }>
  > => {
    return Get<{ url: string }>(
      `/${API}/${DOCUMENTS}/view/${documentId}?format=json`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetERCUploadedTaxReturn = (
    taxYear: number,
    type: string,
  ): Promise<
    MSClientResponse<{
      documents: Document[];
    }>
  > => {
    return Get<{ documents: Document[] }>(
      `/${API}/${DOCUMENTS}/erc/current/${taxYear}/${type}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  UploadDocuments = (
    documents: DocumentForUpload[],
  ): Promise<MSClientResponse<{ documents: Document[] }>> => {
    const formData = new FormData();

    documents.forEach((document) => {
      formData.append('documents', document.file);
    });

    formData.append(
      'descriptions',
      JSON.stringify(documents.map((document) => document.description)),
    );

    formData.append(
      'names',
      JSON.stringify(documents.map((document) => document.name)),
    );

    return Post<{ documents: Document[] }>(
      `/${API}/${DOCUMENTS}/kyc-docs`,
      [201],
      formData,
      this.wrapRequestOptions({}),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   * Public version of {@link UploadCompanyDocumentsPublic}
   *
   * Allow the upload of multiple documents at once instead of separately.
   * programId is optional. The company id and the documents to upload are not.
   */
  UploadCompanyDocuments = (
    req: UploadCompanyDocumentsRequest,
  ): Promise<MSClientResponse<{ documents: Document[] }>> => {
    const formData = new FormData();

    req.documents.forEach((document) => {
      formData.append('documents', document.file);
    });

    formData.append(
      'descriptions',
      JSON.stringify(req.documents.map((document) => document.description)),
    );

    formData.append(
      'names',
      JSON.stringify(req.documents.map((document) => document.name)),
    );

    formData.append(
      'states',
      JSON.stringify(
        req.documents.map((document) =>
          document.state ? document.state : undefined,
        ),
      ),
    );

    formData.append(
      'sources',
      JSON.stringify(
        req.documents.map((document) =>
          document.source ? document.source : undefined,
        ),
      ),
    );

    if (req.programId) {
      formData.append('programId', req.programId);
    }

    if (req.emailOps !== undefined) {
      formData.append('emailOps', req.emailOps ? 'true' : 'false');
    }

    return Post<{ documents: Document[] }>(
      `/${API}/${DOCUMENTS}/multiple-docs`,
      [201],
      formData,
      this.wrapRequestOptions({}),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   * Public version of {@link UploadCompanyDocumentPublic}
   * @deprecated this function has no server side api.
   * Use {@link UploadCompanyDocuments} instead
   */
  UploadCompanyDocument = (
    companyId: number,
    file: File | Blob | FileHandle,
    name: string,
    desc: string,
    programId?: string,
    partner?: string | null,
    taxYear?: number | null,
    documentType?: DocumentType | null,
  ): Promise<MSClientResponse<{ document: Document }>> => {
    const params: {
      document: File | Blob | FileHandle;
      name: string;
      desc: string;
      programId?: string;
      partner?: string;
      taxYear?: string;
      documentType?: DocumentType;
    } = {
      document: file,
      name,
      desc,
    };

    if (programId) params['programId'] = programId;
    if (partner) params['partner'] = partner;
    if (taxYear) params['taxYear'] = String(taxYear);
    if (documentType) params['documentType'] = documentType;

    return Upload<{ document: Document }>(
      'post',
      `/${API}/${DOCUMENTS}/current`,
      [201],
      params,
      this.wrapRequestOptions({}),
    ).then((res) => res);
  };

  /**
   * Public version of this route {@link DeleteDocumentPublic}
   */
  DeleteDocument = (documentId: number): Promise<MSClientResponse<any>> => {
    return Post<{ document: Document }>(
      `/${API}/${DOCUMENTS}/delete/${documentId}/current`,
      [200],
      undefined, // Body
    ).then(({ res }) => {
      return res;
    });
  };

  UploadTaxFilingDocuments = (
    programId: number,
    documents: DocumentForUpload[],
    taxFilingDate: string,
    generateForm8974: boolean,
  ): Promise<MSClientResponse<any>> => {
    const formData = new FormData();

    documents.forEach((document) => {
      formData.append('documents', document.file);
    });

    formData.append(
      'descriptions',
      JSON.stringify(documents.map((document) => document.description)),
    );

    formData.append(
      'names',
      JSON.stringify(documents.map((document) => document.name)),
    );

    formData.append('taxFilingDate', taxFilingDate);
    formData.append('programId', programId.toString());
    formData.append('generateForm8974', generateForm8974.toString());

    return Post<any>(
      `/${API}/${DOCUMENTS}/tax-filing-docs`,
      [201],
      formData,
      this.wrapRequestOptions({}),
    ).then(({ res }) => {
      return res;
    });
  };

  // ========== Qualification Flow ==========
  UpdateRdActivities = (
    req: RdActivities,
  ): Promise<
    MSClientResponse<{
      companyData: CompanyData;
    }>
  > => {
    return Put<RdActivities, { companyData: CompanyData }>(
      `/${APIV1}/${COMPANY}/${QUALIFICATION}/rd-activities`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  UpdateIrsTestPartFour = (
    req: IrsTestPartFour,
  ): Promise<
    MSClientResponse<{
      companyData: CompanyData;
      autoqualificationStatus: AutoqualificationStatusEnum;
    }>
  > => {
    return Put<
      IrsTestPartFour,
      {
        companyData: CompanyData;
        autoqualificationStatus: AutoqualificationStatusEnum;
      }
    >(
      `/${APIV1}/${COMPANY}/${QUALIFICATION}/irs-test-part-four`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  GetBatchSurveyQuestionsBySurveyName = (
    surveyNames: SurveyNameEnum[],
  ): Promise<
    MSClientResponse<
      Record<
        SurveyNameEnum,
        {
          questionGroupData: CmsQuestionGroupData;
        }
      >
    >
  > => {
    return Get<
      Record<
        SurveyNameEnum,
        {
          questionGroupData: CmsQuestionGroupData;
        }
      >
    >(
      `/${APIV1}/${SURVEY}/batch/questions?surveyNames=${surveyNames.join(
        '&surveyNames=',
      )}`,
      [200],
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  GetSurveyQuestionsBySurveyName = (
    surveyName: SurveyNameEnum,
  ): Promise<
    MSClientResponse<{
      questionData: CmsQualificationQuestionDetails;
      requiredQuestionIds: string[];
      questionOrder: string[];
    }>
  > => {
    return Get<{
      questionData: CmsQualificationQuestionDetails;
      requiredQuestionIds: string[];
      questionOrder: string[];
    }>(
      `/${APIV1}/${SURVEY}/${surveyName}/questions`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route is {@link GetSurveyQuestionGroupBySurveyNamePublic}
   */
  GetSurveyQuestionGroupBySurveyName = (
    surveyName: SurveyNameEnum,
  ): Promise<
    MSClientResponse<{
      questionGroupData: CmsQuestionGroupData;
    }>
  > => {
    return Get<{ questionGroupData: CmsQuestionGroupData }>(
      `/${APIV1}/${SURVEY}/${surveyName}/question-group`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route is {@link UpdateCompanyWithSurveyResponse}
   */
  UpdateCompanyWithSurveyResponse = (
    companyId: number,
    surveyName: SurveyNameEnum,
    surveyResponse: SurveyResponse,
  ): Promise<
    MSClientResponse<{
      updatedCompany: CompanyData;
      autoqualificationStatus?: AutoqualificationStatusEnum;
    }>
  > => {
    return Put<
      SurveyResponse,
      {
        updatedCompany: CompanyData;
        autoqualificationStatus?: AutoqualificationStatusEnum;
      }
    >(
      `/${APIV1}/${COMPANY}/${companyId}/${SURVEY}/${surveyName}`,
      [200],
      surveyResponse,
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanyWithRequalSurveyResponse = (
    companyId: number,
    surveyName: SurveyNameEnum,
    surveyResponse: SurveyResponse,
  ): Promise<MSClientResponse<{ updatedCompany: CompanyData }>> => {
    return Put<SurveyResponse, { updatedCompany: CompanyData }>(
      `/${APIV1}/${COMPANY}/${companyId}/${REQUAL_SURVEY}/${surveyName}`,
      [200],
      surveyResponse,
      this.wrapRequestOptions({}),
    );
  };

  // ========== Billing ==========

  GetDefaultPaymentMethod = (
    stripeCustomerId: string,
    signal?: AbortSignal,
  ): Promise<
    MSClientResponse<{
      defaultPaymentMethod: StripePaymentMethod | null;
    }>
  > => {
    return fetch(
      `/${API}/${COMPANY}/${BILLING}/default-payment-method/${stripeCustomerId}`,
      this.wrapRequestOptions({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        signal: signal ? signal : null,
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then(
        (
          json: MSApiResponse<{
            defaultPaymentMethod: StripePaymentMethod | null;
          }>,
        ) => {
          if (json.status !== 200) {
            return { errorMsg: json.errorMsg };
          }
          return { data: json.data };
        },
      )
      .catch((e: any) => {
        return {
          errorMsg: `${somethingWentWrong} ${e}`,
        };
      });
  };

  GetPaymentMethods = (
    stripeCustomerId: string,
    signal?: AbortSignal,
  ): Promise<MSClientResponse<{ paymentMethods: StripePaymentMethod[] }>> => {
    return fetch(
      `/${API}/${COMPANY}/${BILLING}/payment-methods/${stripeCustomerId}`,
      this.wrapRequestOptions({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        signal: signal ? signal : null,
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then(
        (
          json: MSApiResponse<{
            paymentMethods: StripePaymentMethod[];
          }>,
        ) => {
          if (json.status !== 200) {
            return { errorMsg: json.errorMsg };
          }
          return { data: json.data };
        },
      )
      .catch((e: any) => {
        return {
          errorMsg: `${somethingWentWrong} ${e}`,
        };
      });
  };

  GetInvoices = (
    stripeCustomerId: string,
    signal?: AbortSignal,
  ): Promise<MSClientResponse<{ invoices: Stripe.Invoice[] }>> => {
    return fetch(
      `/${API}/${COMPANY}/${BILLING}/invoices/${stripeCustomerId}`,
      this.wrapRequestOptions({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        signal: signal ? signal : null,
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then(
        (
          json: MSApiResponse<{
            invoices: Stripe.Invoice[];
          }>,
        ) => {
          if (json.status !== 200) {
            return { errorMsg: json.errorMsg };
          }
          return { data: json.data };
        },
      )
      .catch((e: any) => {
        return {
          errorMsg: `${somethingWentWrong} ${e}`,
        };
      });
  };

  SetDefaultPaymentMethod = (
    stripeCustomerId: string,
    paymentMethodId: string,
  ): Promise<
    MSClientResponse<{
      defaultPaymentMethod: StripePaymentMethod | null;
    }>
  > => {
    return fetch(
      `/${API}/${COMPANY}/${BILLING}/default-payment-method/${stripeCustomerId}`,
      this.wrapRequestOptions({
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify({ paymentMethodId }),
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then(
        (
          json: MSApiResponse<{
            defaultPaymentMethod: StripePaymentMethod | null;
          }>,
        ) => {
          return {
            data: json.data,
          };
        },
      )
      .catch((e) => {
        return {
          errorMsg: `${somethingWentWrong} ${e}`,
        };
      });
  };

  SetupPaymentIntent = (
    stripeCustomerId: string,
  ): Promise<MSClientResponse<{ secret: string }>> => {
    return fetch(
      `/${API}/${COMPANY}/${BILLING}/setup-payment-intent`,
      this.wrapRequestOptions({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        body: JSON.stringify({ stripeCustomerId }),
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<{ secret: string }>) => {
        if (json.status !== 201) {
          return { errorMsg: json.errorMsg };
        }
        return {
          data: json.data,
        };
      })
      .catch((e) => {
        return {
          errorMsg: `${somethingWentWrong} ${e}`,
        };
      });
  };

  DeletePaymentMethod = (
    paymentMethodId: string,
  ): Promise<MSClientResponse<any>> => {
    return fetch(
      `/${API}/${COMPANY}/${BILLING}/payment-method/${paymentMethodId}`,
      this.wrapRequestOptions({
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<any>) => {
        return {
          data: json.data,
        };
      })
      .catch((e) => {
        return {
          errorMsg: `${somethingWentWrong} ${e}`,
        };
      });
  };

  GetProgramsWithBillingSchedules = (
    companyId: number,
    signal?: AbortSignal,
  ): Promise<
    MSClientResponse<{
      programs: ProgramData[];
    }>
  > => {
    return fetch(
      `/${API}/${COMPANY}/${BILLING}/program-schedule/${companyId}`,
      this.wrapRequestOptions({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        cache: 'no-cache',
        signal: signal ? signal : null,
      }),
    )
      .then((res) => {
        return res.json();
      })
      .then(
        (
          json: MSApiResponse<{
            programs: ProgramData[];
          }>,
        ) => {
          if (json.status !== 200) {
            return { errorMsg: json.errorMsg };
          }
          return { data: json.data };
        },
      )
      .catch((e: any) => {
        return {
          errorMsg: `${somethingWentWrong} ${e}`,
        };
      });
  };

  CreateStripeOauthState = (
    companyId: number,
  ): Promise<MSClientResponse<{ state: string }>> => {
    return Post<{ state: string }>(
      `/${APIV1}/${OAUTH}/stripe-oauth-state`,
      [201],
      JSON.stringify({ companyId }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetConnectedAccountDashboardLink = (
    companyId: number,
  ): Promise<
    MSClientResponse<{
      url: string;
    }>
  > => {
    return Get<{ url: string }>(
      `/${APIV1}/${COMPANY}/${BILLING}/connected-account-url/${companyId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetConnectedAccountDetails = (
    companyId: number,
  ): Promise<
    MSClientResponse<{
      bankName: string | null;
      hasDebitCardLinked: boolean;
    }>
  > => {
    return Get<{ bankName: string | null; hasDebitCardLinked: boolean }>(
      `/${APIV1}/${COMPANY}/${BILLING}/connected-account-details/${companyId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  InitiateStripePayout = (
    companyId: number,
    amountCents: number,
  ): Promise<MSClientResponse<any>> => {
    return Post<{ state: string }>(
      `/${APIV1}/${COMPANY}/${BILLING}/payout`,
      [201],
      JSON.stringify({ companyId, amountCents }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetAdvanceCreditAvailableBalance = (
    companyId: number,
  ): Promise<
    MSClientResponse<{
      balanceCents: number;
    }>
  > => {
    return Get<{ balanceCents: number }>(
      `/${APIV1}/${COMPANY}/${BILLING}/available-balance/${companyId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ========== Order ==========
  GetAllOrderForms = (): Promise<
    MSClientResponse<{
      orderForms?: OrderForm[] | undefined;
      error?: Error | undefined;
    }>
  > => {
    return Get<{
      orderForms?: OrderForm[] | undefined;
      error?: Error | undefined;
    }>(`/${APIV1}/${ORDER}/all`, [200], this.addSharedHeaders({}));
  };

  GetOrCreateOrderFormData = (
    programId: number,
  ): Promise<
    MSClientResponse<{
      order: OrderForm;
    }>
  > => {
    return Get<{ order: OrderForm }>(
      `/${APIV1}/${ORDER}?program_id=${programId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  AcceptOrderForm = (
    programId: number,
    acceptedBy: AcceptedByData,
  ): Promise<MSClientResponse<any>> => {
    return Post<MSClientResponse<any>>(
      `/${APIV1}/${ORDER}/accept`,
      [200],
      JSON.stringify({ programId, acceptedBy }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetBenefitStartMonth = (
    programId: number,
  ): Promise<
    MSClientResponse<{
      benefitStartMonth: number;
    }>
  > => {
    return Get<{ benefitStartMonth: number }>(
      `/${APIV1}/${ORDER}/benefit-start-month?program_id=${programId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetOrdersByYear = (
    taxYear: number,
  ): Promise<
    MSClientResponse<{
      orderForms: OrderForm[];
    }>
  > => {
    return Get<{ orderForms: OrderForm[] }>(
      `/${APIV1}/${ORDER}/${ALL}?tax_year=${taxYear}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ========== Program ==========

  /**
   * Public version of this route {@link GetProgramPublic}
   */
  GetProgram = (
    programId: string | number,
  ): Promise<
    MSClientResponse<{
      program: ProgramData;
    }>
  > => {
    return Get<{ program: ProgramData }>(
      `/${API}/${PROGRAM}/${programId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route {@link UpdateProgramPublic}
   */
  UpdateProgram = (
    programId: string | number,
    req: ClientUpdateProgramRequest,
  ): Promise<
    MSClientResponse<{
      updatedProgram: ProgramData;
    }>
  > => {
    return Put<ClientUpdateProgramRequest, { updatedProgram: ProgramData }>(
      `/${API}/${PROGRAM}/${programId}`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route is {@link UpdateProgramsPublic}
   */
  UpdatePrograms = (
    req: UpdateProgramsRequest,
  ): Promise<MSClientResponse<{ programs: ProgramData[] }>> => {
    return Put<UpdateProgramsRequest, { programs: ProgramData[] }>(
      `/${APIV1}/${PROGRAM}/bulk`,
      [200],
      req,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  UpdateProgramV1 = (
    programId: string | number,
    req: UpdateProgramRequest,
  ): Promise<MSClientResponse<{ updatedProgram: ProgramData }>> => {
    return Put<UpdateProgramRequest, { updatedProgram: ProgramData }>(
      `/${APIV1}/${PROGRAM}/${programId}`,
      [200],
      req,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   * V1 of Create Program.  Given a program name and tax year, create a program.
   */
  CreateProgramV1 = (
    req: CreateProgramRequest,
  ): Promise<MSClientResponse<CreateProgramRequest>> => {
    return Post<CreateProgramRequest>(
      `/${APIV1}/${PROGRAM}`,
      [201],
      JSON.stringify(req),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   * Public version of this route {@link GetRDProjectsPublic}
   */
  GetRDProjects = (
    programId: string | number,
  ): Promise<
    MSClientResponse<{
      rdProjects: RdProject[];
    }>
  > => {
    return Get<{ rdProjects: RdProject[] }>(
      `/${APIV1}/${PROGRAM}/${programId}/${RD_PROJECTS}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route {@link CreateRDProjectPublic}
   */
  CreateRDProject = (
    programId: string | number,
    project: CreateRdProject,
  ): Promise<MSClientResponse<RdProject>> => {
    return Post<any>(
      `/${APIV1}/${PROGRAM}/${programId}/rdProjects`,
      [201],
      JSON.stringify(project),
      this.addSharedHeaders({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  UpdateRDProject = (
    programId: string | number,
    projectId: number,
    req: UpdateRdProjectRequest,
  ): Promise<MSClientResponse<any>> => {
    return Put<UpdateRdProjectRequest, UpdateRdProjectRequest>(
      `/${APIV1}/${PROGRAM}/${programId}/rdProjects/${projectId}`,
      [200],
      req,
      this.addSharedHeaders({}),
    );
  };

  DeleteRDProject = (
    programId: string | number,
    rdProjectId: number,
  ): Promise<MSClientResponse<any>> => {
    return Delete<DeleteRdProjectRequest>(
      `/${APIV1}/${PROGRAM}/${programId}/rdProjects/${rdProjectId}`,
      [200],
    );
  };

  // ========== Project ==========

  BackFillWorkDone = (taxYear: number) => {
    return Post<{ employmentRecords: EmploymentRecordData[] }>(
      `/${API}/${WORK_DONE}/backfill`,
      [201],
      JSON.stringify({ taxYear }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   *  Public version of this route {@link PrefillWorkDonePublic}
   */
  PrefillWorkDone = (taxYear: number) => {
    return Post<{ employmentRecords: EmploymentRecordWithWorkDoneData[] }>(
      `/${API}/${WORK_DONE}/pull-forward-prefill`,
      [201],
      JSON.stringify({ taxYear }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  ValidateWorkDoneData = (workDone: WorkDoneData) => {
    if (!workDone.discussion) {
      return false;
    }
    if (
      workDone.discussion.percent_estimate_low === undefined ||
      workDone.discussion.percent_estimate_high === undefined
    ) {
      return false;
    }
    if (workDone.percentage === undefined) {
      return false;
    }
    if (!workDone.employmentRecord) {
      return false;
    }
    return true;
  };

  GetProject = (
    projectId: string | number,
  ): Promise<
    MSClientResponse<{
      project: ProjectData;
    }>
  > => {
    return Get<{ project: ProjectData }>(
      `/${API}/${PROJECT}/${projectId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  UpdateProject = (
    projectId: number,
    req: UpdateProjectRequest,
  ): Promise<
    MSClientResponse<{
      project: ProjectData;
    }>
  > => {
    return Put<UpdateProjectRequest, { project: ProjectData }>(
      `/${API}/${PROJECT}/${projectId}`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route {@link UpdateExpenseClassificationPublic}
   */
  UpdateExpenseClassification = (
    updates: UpdateWorkDoneRequest[],
  ): Promise<
    MSClientResponse<{
      updatedWorkDone: WorkDoneData[];
    }>
  > => {
    return Put<
      {
        updates: UpdateWorkDoneRequest[];
      },
      {
        updatedWorkDone: WorkDoneData[];
      }
    >(
      `/${API}/${WORK_DONE}/classification`,
      [200],
      { updates },
      this.wrapRequestOptions({}),
    ).then((res) => {
      if (!res.data) {
        return res;
      }
      const { updatedWorkDone } = res.data;
      if (
        updatedWorkDone.filter(this.ValidateWorkDoneData).length !==
        updatedWorkDone.length
      ) {
        // TODO: This should return an error code, not just a message
        return {
          errorMsg: `An error occurred loading. Please reach out to contact${AT_MS_DOMAIN}`,
        };
      }

      return res;
    });
  };

  // ======== RD Verification =======

  UpdateGeneralRDQuestions = async (
    programId: number,
    programRdQA: ProgramRdQA[],
    financialInformation: FinancialInformation,
    moveToNextStep: boolean,
  ): Promise<MSClientResponse<{ programData: ProgramData }>> => {
    return Put<
      {
        programRdQA: ProgramRdQA[];
        financialInformation: FinancialInformation;
        moveToNextStep: boolean;
      },
      { programData: ProgramData }
    >(
      `/${APIV1}/${PROGRAM}/${programId}/rd-questions`,
      [200],
      { programRdQA, financialInformation, moveToNextStep },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  // ================ Config ========================

  GetConfigFromTable = (
    name: string,
  ): Promise<MSClientResponse<{ name: string; data: any }>> => {
    return Get<{ name: string; data: any }>(
      `/${APIV1}/${CONFIG}?name=${name}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ================ Accounting ============================

  CreateCodatCompany = (): Promise<
    MSClientResponse<{ redirectUrl: string }>
  > => {
    return Post<{ redirectUrl: string }>(
      `/${APIV1}/${ACCOUNTING}/companies`,
      [201],
      JSON.stringify({}),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  AddCodatConnection = (): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${APIV1}/${ACCOUNTING}/companies/connections`,
      [200, 201],
      JSON.stringify({}),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetCompanyCodatInfo = (
    companyId: number,
  ): Promise<
    MSClientResponse<{
      companyInfo: CompanyInfo;
    }>
  > => {
    return Get<{
      companyInfo: CompanyInfo;
    }>(
      `/${APIV1}/${ACCOUNTING}/company/${companyId}/info`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ================ Service Tokens ========================

  StoreFinchServiceToken = async (
    code: string,
    importType: ImportType,
    token?: string,
    email?: string,
    taxYear?: number,
  ): Promise<MSClientResponse<{ isManualToken: boolean }>> => {
    return Post<{ isManualToken: boolean }>(
      `/${APIV1}/${EXTERNAL_SERVICE_TOKEN}/add-finch-token`,
      [201],
      JSON.stringify({
        code,
        importType,
        token,
        email,
        taxYear,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  FinchLinkedServices = (
    token?: string,
    email?: string,
  ): Promise<
    MSClientResponse<{
      services: FinchPayrollProvider[];
      expiredServices: FinchPayrollProvider[];
    }>
  > => {
    return Get<{
      services: FinchPayrollProvider[];
      expiredServices: FinchPayrollProvider[];
    }>(
      `/${APIV1}/${EXTERNAL_SERVICE_TOKEN}/payroll-linked-services${
        token ? `?token=${token}&email=${encodeURIComponent(email || '')}` : ''
      }`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ================ Payroll Admin Tokens ========================

  FinchTokenStatus = (): Promise<
    MSClientResponse<{ hasManualToken: boolean; hasToken: boolean }>
  > => {
    return Get<{ hasManualToken: boolean; hasToken: boolean }>(
      `/${APIV1}/${EXTERNAL_SERVICE_TOKEN}/finch-token-status`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetPayrollAdminLink = (
    companyId: number,
  ): Promise<MSClientResponse<{ link: string }>> => {
    return Get<{ link: string }>(
      `/${APIV1}/${PAYROLL_ACCESS_LINK}/${COMPANY}/${companyId}`,
      [200, 404],
      this.wrapRequestOptions({}),
    );
  };

  GeneratePayrollAdminLink = (
    companyId: number,
  ): Promise<MSClientResponse<{ link: string }>> => {
    return Post<{ link: string }>(
      `/${APIV1}/${PAYROLL_ACCESS_LINK}/${COMPANY}/${companyId}`,
      [201],
      '',
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  SendPayrollAdminLink = (
    companyId: number,
    email: string,
    link: string,
  ): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${APIV1}/${PAYROLL_ACCESS_LINK}/${COMPANY}/${companyId}/${EMAIL_LINK}`,
      [200],
      JSON.stringify({ link, email }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  ValidatePayrollAdminLink = (
    token: string,
    email: string,
  ): Promise<
    MSClientResponse<{
      payrollSystem: string;
      companyName: string;
    }>
  > => {
    return Post<{ payrollSystem: string; companyName: string }>(
      `/${APIV1}/${PAYROLL_ACCESS_LINK}/verify`,
      [200],
      JSON.stringify({ token, email }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  // ================ Document Access Link ========================

  GetDocumentAccessLink = (
    companyId: number,
    programId: number,
    taxYear?: number,
  ): Promise<MSClientResponse<{ link: string }>> => {
    const url = `/${APIV1}/accountant/${
      taxYear ? 'tax-year' : 'program'
    }/${COMPANY}/${companyId}/${taxYear ?? programId}`;
    return Get<{ link: string }>(url, [200, 404], this.wrapRequestOptions({}));
  };

  GenerateDocumentAccessLink = (
    companyId: number,
    programId: number,
    taxYear?: number,
  ): Promise<MSClientResponse<{ link: string }>> => {
    const url = `/${APIV1}/accountant/${
      taxYear ? 'tax-year' : 'program'
    }/${COMPANY}/${companyId}/${taxYear ?? programId}`;
    return Post<{ link: string }>(
      url,
      [201],
      '',
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  // ==== Cache testing =====

  CacheGet = (
    key: string,
  ): Promise<MSClientResponse<{ value: string | null }>> => {
    return Get<{ value: string | null }>(
      `/${APIV1}/${COMPANY}/cache-get?key=${key}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  CacheSet = (
    key: string,
    value: string,
  ): Promise<MSClientResponse<{ key: string; value: string }>> => {
    return Get<{ key: string; value: string }>(
      `/${APIV1}/${COMPANY}/cache-set?key=${key}&value=${value}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  CheckCreditEstimateLoaded = async (): Promise<
    MSClientResponse<{ resourceLink: string; loadingStatus?: string }>
  > => {
    return Post<{ resourceLink: string; loadingStatus?: string }>(
      `/${APIV1}/${COMPANY}/${LOADING_STATUS}`,
      [202, 302],
      JSON.stringify({ type: LoadingStatusTypeEnum.PAYROLL }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  // ==== Accountant Link Access ====

  ValidateAccountantTaxYearAccessLink = (
    taxYear: number,
    email: string,
    token: string,
  ): Promise<
    MSClientResponse<{
      documents: Document[];
      companyName: string;
      programs: Program[];
    }>
  > => {
    return Post<{
      documents: Document[];
      companyName: string;
      programs: Program[];
    }>(
      `/${APIV1}/accountant/tax-year/verify`,
      [200],
      JSON.stringify({ token, email, taxYear }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  ValidateAccountantAccessLink = (
    programId: number,
    email: string,
    token: string,
  ): Promise<
    MSClientResponse<{
      programData: ProgramData;
      companyName: string;
    }>
  > => {
    return Post<{ programData: ProgramData; companyName: string }>(
      `/${APIV1}/accountant/program/verify`,
      [200],
      JSON.stringify({ token, email, programId }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  // ====== Coupons ======

  UnusedCoupon = (
    intent: string,
  ): Promise<MSClientResponse<{ success: boolean }>> => {
    return Put<{ intent: string }, { success: boolean }>(
      `/${APIV1}/coupon/unused`,
      [200],
      { intent },
      this.wrapRequestOptions({}),
    );
  };

  SendCouponEmail = (intent: string): Promise<MSClientResponse<any>> => {
    return Post<{ intent: string }>(
      `/${APIV1}/coupon/email`,
      [201],
      JSON.stringify({ intent }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetCompanyIssuedSurvey = (
    issuedSurveyId: number,
  ): Promise<MSClientResponse<{ issuedSurvey: IssuedSurvey }>> => {
    return Get<{ issuedSurvey: IssuedSurvey }>(
      `/${APIV1}/${ISSUED_SURVEY}/${issuedSurveyId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanyIssuedSurvey = (
    companyId: number,
    surveyName: SurveyNameEnum,
    status: IssuedSurveyStatusEnum,
  ): Promise<MSClientResponse<never>> => {
    return Put<{ status: IssuedSurveyStatusEnum }, never>(
      `/${APIV1}/${COMPANY}/${companyId}/${ISSUED_SURVEY}/${surveyName}`,
      [200],
      { status },
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanyWithNewIssuedSurvey = (
    companyId: number,
    surveyName: SurveyNameEnum,
  ): Promise<MSClientResponse<{ updatedCompany?: CompanyData }>> => {
    return Post<{ updatedCompany?: CompanyData }>(
      `/${APIV1}/${COMPANY}/${companyId}/${SURVEY}/${surveyName}`,
      [201],
      JSON.stringify({}),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   * Public version of this route {@link  UpdateCompanyEventAttestationPublic}
   */
  UpdateCompanyEventAttestation = (
    companyId: number,
    eventType: AttestationEventType,
    taxYear: number,
    userEmail: string,
  ): Promise<MSClientResponse<any>> => {
    return Put<
      {
        eventType: AttestationEventType;
        taxYear: number;
        userEmail: string;
      },
      any
    >(
      `/${APIV1}/${COMPANY}/${companyId}/event-attestation`,
      [201],
      {
        taxYear,
        eventType,
        userEmail,
      },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  GetCompanyEventAttestation = (
    companyId: number,
    taxYear: number,
    eventType: AttestationEventType,
  ): Promise<MSClientResponse<{ confirmedFiling: boolean }>> => {
    return Get<{ confirmedFiling: boolean }>(
      `/${APIV1}/${COMPANY}/${companyId}/event-attestation/${taxYear}/${eventType}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ====== Employee Magic Links ======

  GetEmployeeMagicLinkPageData = (
    companyId: number,
    employeeId: number,
    tokenPurpose: string,
    token: string,
  ): Promise<
    MSClientResponse<{
      questionData?: Record<string, any>;
      employeeData: { name: string; companyName: string };
    }>
  > => {
    return Get<{
      questionData?: Record<string, any>;
      employeeData: { name: string; companyName: string };
    }>(
      `/${APIV1}/${EMPLOYEE_ACCESS_LINK}/${COMPANY}/${companyId}/${EMPLOYEE}/${employeeId}/${tokenPurpose}?token=${token}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  SaveEmployeeMagicLinkResponse = (
    companyId: number,
    employeeId: number,
    tokenPurpose: string,
    token: string,
    employeeResponse: Record<string, any>,
  ): Promise<MSClientResponse<any>> => {
    return Post<{ intent: string }>(
      `/${APIV1}/${EMPLOYEE_ACCESS_LINK}/${COMPANY}/${companyId}/${EMPLOYEE}/${employeeId}/${tokenPurpose}?token=${token}`,
      [201],
      JSON.stringify(employeeResponse),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetEmployeeTableData = (
    companyId: number,
    taxYear: number,
    purpose: EmployeeTokenPurposeEnum,
  ): Promise<MSClientResponse<{ employees: EmployeeForEmployeeTable[] }>> => {
    return Get<{ employees: EmployeeForEmployeeTable[] }>(
      `/${API}/${EMPLOYMENT_RECORD}/${COMPANY}/employee-table-data/${taxYear}/${purpose}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  SaveEmployeePrimaryEmailAddress = (
    employmentRecordId: number,
    primaryEmailAddress: string,
  ): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${API}/${EMPLOYMENT_RECORD}/${employmentRecordId}/primary-email-address`,
      [201],
      JSON.stringify({ primaryEmailAddress }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  UpdateCompanyDetailsInfo = (
    req: UpdateCompanyDetailsInfoRequest,
  ): Promise<
    MSClientResponse<{
      company: CompanyData;
      hasQualifyingPrograms: boolean;
    }>
  > => {
    return Put<
      UpdateCompanyDetailsInfoRequest,
      { company: CompanyData; hasQualifyingPrograms: boolean }
    >(`/${APIV1}/${COMPANY}/details`, [200], req, this.wrapRequestOptions({}));
  };

  UpdateEmployeeDetailsInfo = (
    req: UpdateEmployeeDetailsInfoRequest,
  ): Promise<
    MSClientResponse<{
      company: CompanyData;
      hasQualifyingPrograms: boolean;
    }>
  > => {
    return Put<
      UpdateEmployeeDetailsInfoRequest,
      { company: CompanyData; hasQualifyingPrograms: boolean }
    >(
      `/${APIV1}/${COMPANY}/employee-details`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanyManualPayrollEstimate = (
    req: UpdateCompanyManualPayrollEstimateRequest,
  ): Promise<MSClientResponse<any>> => {
    return Put<UpdateCompanyManualPayrollEstimateRequest, any>(
      `/${APIV1}/${COMPANY}/manual-payroll-estimate`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanyWithGapPayroll = (
    req: UpdateCompanyWithGapPayrollRequest,
  ): Promise<MSClientResponse<any>> => {
    return Put<UpdateCompanyWithGapPayrollRequest, any>(
      `/${APIV1}/${COMPANY}/gap-payroll-estimate`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanySignupStage = (
    req: UpdateCompanySignupStageRequest,
  ): Promise<MSClientResponse<any>> => {
    return Put<UpdateCompanySignupStageRequest, any>(
      `/${APIV1}/${COMPANY}/signup-stage`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  UpdateCreditEstimate = (
    req: UpdateCreditEstimate,
  ): Promise<
    MSClientResponse<{
      companyData: CompanyData;
    }>
  > => {
    return Put<UpdateCreditEstimate, { companyData: CompanyData }>(
      `/${APIV1}/${COMPANY}/${QUALIFICATION}/generate-order`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  // ====== FAQs ======

  GetFaqsByFaqGroupId = (
    groupId: GraphCmsFaqGroupIdEnum,
  ): Promise<
    MSClientResponse<{
      faqData: CmsFaqData[];
    }>
  > => {
    return Get<{ faqData: CmsFaqData[] }>(
      `/${APIV1}/${FAQ}/${groupId}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ====== High Yield Savings Account ======

  DepositFunds = (
    transferAmountCents: number,
  ): Promise<MSClientResponse<any>> => {
    return Post<MSClientResponse<any>>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/deposit`,
      [200],
      JSON.stringify({ transferAmountCents }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  TransferFunds = (
    req: CustomerTransferRequest,
  ): Promise<MSClientResponse<CustomerTransferResponse>> => {
    const path = `/${APIV1}/${TREASURY_MANAGEMENT}/transfer`;
    const url = `${window.location.href}${path}`;
    const idempotencyId = this.GenerateIdempotencyId({
      endpoint: url,
      method: 'POST',
      parameters: [req],
      timestamp: Date.now(),
      window: 1000 * 10,
    });

    return Post<CustomerTransferResponse>(
      path,
      [200, 201],
      JSON.stringify(req),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
          [IDEMPOTENCY_HEADER]: idempotencyId,
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetAccountTransactions = (): Promise<
    MSClientResponse<{
      txnData: TransactionsData[];
    }>
  > => {
    return Get<{
      transactions: {
        transactionId: string;
        ledgerAccountId: string;
        description: string;
        status: 'pending' | 'posted' | 'archived';
        amountCents: number;
        createdAt: number;
        updatedAt: number;
      }[];
    }>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/transactions`,
      [200],
      this.wrapRequestOptions({}),
    ).then(({ data, errorMsg }) => {
      if (data) {
        return {
          data: {
            txnData: data.transactions.map((txn) => {
              let state: TransactionStateEnum = TransactionStateEnum.Failed;
              if (txn.status === 'pending') {
                state = TransactionStateEnum.Pending;
              } else if (txn.status === 'posted') {
                state = TransactionStateEnum.Completed;
              }
              return {
                // TODO: ledgerAccountId doesn't actually exist on the returned object
                accountId: txn.ledgerAccountId,
                date: txn.createdAt,
                description: txn.description,
                state,
                amountCents: txn.amountCents,
              };
            }),
          },
        };
      } else {
        return { errorMsg };
      }
    });
  };

  CreatePPM = (): Promise<MSClientResponse<any>> => {
    return Post<
      MSClientResponse<{
        id?: string;
        type?: string;
        data?: {
          agreement: string;
          is_signed: boolean;
          created_timestamp: number;
          signed_timestamp?: number;
        };
      }>
    >(
      `/${APIV1}/${TREASURY_MANAGEMENT}/ppm`,
      [200],
      JSON.stringify({}),
      this.wrapRequestOptions({}),
    ).then((data) => {
      return data.res;
    });
  };

  CreatePromissoryNote = (): Promise<MSClientResponse<any>> => {
    return Post<
      MSClientResponse<{
        id?: string;
        type?: string;
        data?: {
          note: string;
          is_signed: boolean;
          created_timestamp: number;
          signed_timestamp?: number;
        };
      }>
    >(
      `/${APIV1}/${TREASURY_MANAGEMENT}/promissory-note`,
      [200],
      JSON.stringify({}),
      this.wrapRequestOptions({}),
    ).then((data) => {
      return data.res;
    });
  };

  SignPPM = (ppmId: string): Promise<MSClientResponse<any>> => {
    return Post<MSClientResponse<any>>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/ppm-sign`,
      [200],
      JSON.stringify({ ppmId }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then((data) => {
      return data.res;
    });
  };

  SignPromissoryNote = (
    promissoryNoteId: string,
  ): Promise<MSClientResponse<any>> => {
    return Post<MSClientResponse<any>>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/promissory-note-sign`,
      [200],
      JSON.stringify({ promissoryNoteId }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then((data) => {
      return data.res;
    });
  };

  GetHysaBalanceInformation = (): Promise<
    MSClientResponse<BalanceInformation>
  > => {
    return Get<BalanceInformation>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/balance`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetHysaAccount = (): Promise<MSClientResponse<TMAccount>> => {
    return Get<TMAccount>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/account`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetTreasuryManagementOnboardingStatus = (): Promise<
    MSClientResponse<{
      status: TMStepStatus;
      balanceInfo: BalanceInformation;
      promissoryNoteExists: boolean;
    }>
  > => {
    return Get<{
      status: TMStepStatus;
      balanceInfo: BalanceInformation;
      promissoryNoteExists: boolean;
    }>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/status`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // ===== Plaid =======
  GeneratePlaidLinkToken = (): Promise<
    MSClientResponse<{ link_token: string }>
  > => {
    return Post<{ link_token: string }>(
      `/${APIV1}/${PLAID}/link_token`,
      [201],
      undefined,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  DeletePlaidLinkToken = async (): Promise<MSClientResponse<any>> => {
    const res = await Delete(
      `/${APIV1}/${PLAID}/link_token`,
      [201, 500],
      this.wrapRequestOptions({}),
    );
    return res;
  };

  PlaidRegistration = (
    public_token: string,
    metadata: any,
  ): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${APIV1}/${PLAID}/register`,
      [201],
      JSON.stringify({ public_token, metadata }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  FetchBankingInformation = (): Promise<
    MSClientResponse<{
      accounts: {
        id: string;
        name: string;
        type: string;
        subtype: string;
        lastFourDigits: string;
        status?: string;
      }[];
      setupOrReconnectRequired?: 'SETUP' | 'RECONNECT';
    }>
  > => {
    return Get<{
      accounts: {
        id: string;
        name: string;
        type: string;
        subtype: string;
        lastFourDigits: string;
      }[];
    }>(
      `/${APIV1}/${PLAID}/accounts`,
      [200],
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  // ====== 8974 ======
  GetCompanyRDCreditBalance = (
    companyId: number,
  ): Promise<
    MSClientResponse<{
      balanceInfo?: {
        balanceAmountCents: number;
        totalAdjustedCents: number;
        totalFundedCents: number;
        totalWithdrawnCents: number;
      };
    }>
  > => {
    return Get<{
      balanceInfo?: {
        balanceAmountCents: number;
        totalAdjustedCents: number;
        totalFundedCents: number;
        totalWithdrawnCents: number;
      };
    }>(
      `/${APIV1}/${ROOT_8974}/${LEDGERS}/${COMPANY}/${companyId}/balance`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetProgramRDCreditBalance = (
    programId: number,
  ): Promise<
    MSClientResponse<{
      balanceAmountCents: number;
    }>
  > => {
    return Get<{ balanceAmountCents: number }>(
      `/${APIV1}/${ROOT_8974}/${LEDGERS}/${PROGRAM}/${programId}/balance`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  Finalize8974Form = (
    companyId: number,
    socialSecurityTax: number,
    medicareTax: number,
  ): Promise<MSClientResponse<{ finalization: Company8974Finalization }>> => {
    return Post<{ finalization: Company8974Finalization }>(
      `/${APIV1}/${ROOT_8974}/${FORMS}/${companyId}/finalize`,
      [201],
      JSON.stringify({ socialSecurityTax, medicareTax }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetCarryoverAndAdjustForQuarter = (
    companyId: number,
    queryDate: Date = new Date(),
  ): Promise<
    MSClientResponse<{
      carryover?: QuarterDescription;
      adjust?: QuarterDescription;
      status?: CarryoverAndAdjustStatusEnum;
      missedFilings?: FilingStatusQuarter[];
      currentQuarter?: FilingStatusQuarter;
    }>
  > => {
    const dateStr = [
      queryDate.getFullYear(),
      queryDate.getMonth() + 1,
      queryDate.getDate(),
    ].join('-');
    const query = `queryDate=${dateStr}`;
    return Get<{
      carryover?: QuarterDescription;
      adjust?: QuarterDescription;
      status?: CarryoverAndAdjustStatusEnum;
    }>(
      `/${APIV1}/${ROOT_8974}/${LEDGERS}/${COMPANY}/${companyId}/active-carryover?${query}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetForm8974DocumentUrl = (
    companyId: number,
    taxYear: number,
    quarter: number,
  ): Promise<
    MSClientResponse<{
      documentUrl: string;
    }>
  > => {
    return Get<{
      documentUrl: string;
    }>(
      `/${APIV1}/${ROOT_8974}/${LEDGERS}/${COMPANY}/${companyId}/taxYear/${taxYear}/quarter/${quarter}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  SubmitKycBusinessData = async (
    request: SubmitBusinessDataRequest,
  ): Promise<MSClientResponse<BusinessResponse>> => {
    return Post<BusinessResponse>(
      `/${APIV1}/${KYC}/filer/business`,
      [201],
      JSON.stringify(request),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  SubmitKycAccreditationApplication = async (): Promise<
    MSClientResponse<SubmitAccreditationResponse>
  > => {
    return Post<SubmitAccreditationResponse>(
      `/${APIV1}/${KYC}/filer/business/accreditation`,
      [201],
      JSON.stringify({ manualReview: true }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetHysaCsrfToken = async (): Promise<
    MSClientResponse<{ csrfToken: string }>
  > => {
    return Get<{ csrfToken: string }>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/csrf-token`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetAccreditationStatusViaKYC = async (): Promise<
    MSClientResponse<{ isAccredited: boolean }>
  > => {
    return Get<{ isAccredited: boolean }>(
      `/${APIV1}/${KYC}/accreditation-status`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetKycBusinessData = async (): Promise<
    MSClientResponse<BusinessApplicationData>
  > => {
    return Get<BusinessApplicationData>(
      `/${APIV1}/${KYC}/filer/business`,
      [200, 404],
      this.wrapRequestOptions({}),
    );
  };

  GetKycApplicationStatus = async (): Promise<
    MSClientResponse<GetKycApplicationStatusResponse>
  > => {
    return Get<GetKycApplicationStatusResponse>(
      `/${APIV1}/${KYC}/status`,
      [200, 403, 404],
      this.wrapRequestOptions({}),
    );
  };

  GetKycBeneficiariesData = async (): Promise<
    MSClientResponse<{
      personApplications: PersonApplicationData[];
    }>
  > => {
    return Get(
      `/${APIV1}/${KYC}/beneficiaries`,
      [200, 404],
      this.wrapRequestOptions({}),
    );
  };

  SubmitKycBeneficiariesData = async (request: {
    beneficiaryApplications: SubmitKycBeneficiariesData;
  }): Promise<MSClientResponse<BeneficiariesResponse>> => {
    return Post<BeneficiariesResponse>(
      `/${APIV1}/${KYC}/beneficiaries`,
      [200, 201],
      JSON.stringify(request),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  SubmitKycBeneficiariesIdImage = (
    beneficiaryApplicationId: string,
    beneficiaryEntityId: string,
    file: File,
  ): Promise<MSClientResponse<SupportingDocumentData>> => {
    return Upload<SupportingDocumentData>(
      `post`,
      `/${APIV1}/${KYC}/beneficiaries/documents/ids`,
      [201],
      {
        idDocument: file,
        applicationId: beneficiaryApplicationId,
        entityId: beneficiaryEntityId,
      },
      this.wrapRequestOptions({}),
    );
  };

  SubmitKycBusinessProofOfFunds = (
    businessApplicationId: string,
    businessEntityId: string,
    file: File,
  ): Promise<MSClientResponse<SupportingDocumentData>> => {
    return Upload<SupportingDocumentData>(
      `post`,
      `/${APIV1}/${KYC}/filer/business/documents/proof-of-funds`,
      [201],
      {
        proofDocument: file,
        applicationId: businessApplicationId,
        entityId: businessEntityId,
      },
      this.wrapRequestOptions({}),
    );
  };

  AddRipplingToken = async (
    code: string,
    importType: ImportType,
    taxYear?: number,
  ): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${APIV1}/external-service-token/add-rippling-token`,
      [201],
      JSON.stringify({ code, importType, taxYear }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  GetMembers = (): Promise<MSClientResponse<GetOrgAdministrators[]>> => {
    return Get<GetOrgAdministrators[]>(
      `/${APIV1}/${COMPANY}/members`,
      [200],
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  SendInvitation = (
    inviteeEmail: string,
    inviteeFirstName: string,
    inviteeLastName: string,
    inviterName: string,
  ): Promise<MSClientResponse<undefined>> => {
    return Post<undefined>(
      `/${APIV1}/company/invitations`,
      [201],
      JSON.stringify({
        inviteeEmail,
        inviteeFirstName,
        inviteeLastName,
        inviterName,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  UpdateProgramStage = (
    req: UpdateProgramStageRequest,
  ): Promise<MSClientResponse<any>> => {
    return Put<UpdateProgramStageRequest, any>(
      `/${APIV1}/${PROGRAM}/program-stage`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  UpdateProgramSubStage = (
    req: UpdateProgramSubStageRequest,
  ): Promise<MSClientResponse<any>> => {
    return Put<UpdateProgramSubStageRequest, any>(
      `/${APIV1}/${PROGRAM}/program-substage`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  UpdateProgramTaxFilingDate = (
    req: UpdateProgramTaxFilingDateRequest,
  ): Promise<MSClientResponse<any>> => {
    return Put<UpdateProgramTaxFilingDateRequest, any>(
      `${APIV1}/${PROGRAM}/program-tax-filing-date`,
      [200],
      req,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  DeleteExpectedPayment = (id: string): Promise<MSClientResponse<any>> => {
    const path = `/${APIV1}/${TREASURY_MANAGEMENT}/${PROMISSORY_NOTE}/expected-payments/${id}`;
    const url = `${window.location.href}${path}`;
    const idempotencyId = this.GenerateIdempotencyId({
      endpoint: url,
      method: 'POST',
      parameters: [id],
      timestamp: Date.now(),
      window: 1000 * 10,
    });
    return Delete(
      path,
      [200],
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
          [IDEMPOTENCY_HEADER]: idempotencyId,
        },
      }),
    );
  };

  ListExpectedPayments = (): Promise<
    MSClientResponse<ModernTreasuryExpectedPayment[]>
  > => {
    return Get<ModernTreasuryExpectedPayment[]>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/${PROMISSORY_NOTE}/expected-payments`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetActivePromissoryNotes = (): Promise<
    MSClientResponse<PromissoryNote[]>
  > => {
    return Get<PromissoryNote[]>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/${PROMISSORY_NOTE}/active`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route {@link GetRdVendorExpensesPublic}
   */
  GetRdVendorExpenses = (
    programId: number,
  ): Promise<MSClientResponse<RdVendorExpense[]>> => {
    return Get<RdVendorExpense[]>(
      `/${APIV1}/${PROGRAM}/${programId}/rd-vendor-expenses`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route {@link PostRdVendorExpensesPublic}
   */
  PostRdVendorExpenses = (
    programId: number,
    vendor: RdVendorExpense,
  ): Promise<MSClientResponse<RdVendorExpense>> => {
    return Post<RdVendorExpense>(
      `/${APIV1}/${PROGRAM}/${programId}/rd-vendor-expenses`,
      [201],
      JSON.stringify(vendor),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  PutRdVendorExpenses = (
    programId: number,
    vendor: RdVendorExpense,
  ): Promise<MSClientResponse<RdVendorExpense>> => {
    return Put<RdVendorExpense, RdVendorExpense>(
      `/${APIV1}/${PROGRAM}/${programId}/rd-vendor-expenses/${vendor.id}`,
      [200],
      vendor,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then((res) => res);
  };

  /**
   *  Public version of this route {@link DeleteAttachmentsFromVendorExpensePublic}
   */
  DeleteAttachmentsFromVendorExpense = (
    programId: number,
    vendorId: number,
    docIds: number[],
  ): Promise<MSClientResponse<{ deletedIds: number[] }>> => {
    return Put<number[], { deletedIds: number[] }>(
      `/${APIV1}/${PROGRAM}/${programId}/rd-vendor-expenses/${vendorId}/receipts:bulkDelete`,
      [200, 201],
      docIds,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   *  Public version of this route {@link DeleteRdVendorExpensePublic}
   */
  DeleteRdVendorExpense = (
    programId: number,
    vendor: RdVendorExpense,
  ): Promise<MSClientResponse<RdVendorExpense>> => {
    return Delete<RdVendorExpense>(
      `/${APIV1}/${PROGRAM}/${programId}/rd-vendor-expenses/${vendor.id}`,
      [200],
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then((res) => res);
  };

  /**
   *  Public version of this route {@link PutRdVendorExpenseReceiptsPublic}
   */
  PutRdVendorExpenseReceipts = (
    programId: number,
    vendor: RdVendorExpense,
    docIdsToAttach: number[],
  ): Promise<MSClientResponse<any>> => {
    const docIds = docIdsToAttach.map((docId) => ({
      documentId: docId,
    }));
    return Put<{ documentId: number }[], any>(
      `/${APIV1}/${PROGRAM}/${programId}/rd-vendor-expenses/${vendor.id}/receipts`,
      [200],
      docIds,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  CreatePromissoryNoteSignUrl = (
    signatureUrlRequest: SignPromissoryNoteReqBody,
  ): Promise<MSClientResponse<any>> => {
    return Post<SignPromissoryNoteReqBody>(
      `/${APIV1}/${TREASURY_MANAGEMENT}/signature-url`,
      [201],
      JSON.stringify(signatureUrlRequest),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  SavePromissoryNoteSignatureSuceess = (
    saveSignatureSucessRequest: SignPromissoryNoteReqBody,
  ): Promise<MSClientResponse<any>> => {
    const path = `/${APIV1}/${TREASURY_MANAGEMENT}/signature-success`;
    const url = `${window.location.href}${path}`;
    const idempotencyId = this.GenerateIdempotencyId({
      endpoint: url,
      method: 'POST',
      parameters: [saveSignatureSucessRequest],
      timestamp: Date.now(),
      window: 1000 * 10,
    });

    return Post<SignPromissoryNoteReqBody>(
      path,
      [201],
      JSON.stringify(saveSignatureSucessRequest),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
          [IDEMPOTENCY_HEADER]: idempotencyId,
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   *   UpdateAccountCreation : method to update account creation data
   */
  UpdateAccountCreation = (
    req: AccountCreationRequest,
    step: AccountCreationSteps,
    isUnified?: boolean,
  ): Promise<MSClientResponse<{ company: CompanyData }>> => {
    return Put<AccountCreationRequest, { company: CompanyData }>(
      `/${APIV1}/${COMPANY}/account-creation/${step}`,
      [200],
      { ...req, unified: isUnified },
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Sourcing data refers to marketing, referral data collected on signup.
   */
  UpdateCompanySourcingData = (
    data: UpdateCompanySourcingDataRequest,
  ): Promise<MSClientResponse<any>> => {
    return Put<UpdateCompanySourcingDataRequest, any>(
      `/${APIV1}/${COMPANY}/sourcing-data`,
      [204],
      data,
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanyMisc = async (
    req: PatchCompanyMiscRequest,
  ): Promise<MSClientResponse<{ company: CompanyData }>> => {
    return Put<PatchCompanyMiscRequest, any>(
      `/${APIV1}/${COMPANY}/misc`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  GetPrograms = (): Promise<MSClientResponse<ProgramData[]>> => {
    return Get<ProgramData[]>(
      `/${APIV1}/${PROGRAM}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  SendAIChatbotMessage = (
    text: string,
  ): Promise<MSClientResponse<{ result: string }>> => {
    return Post<{ result: string }>(
      `/${APIV1}/${CHATBOT_API}/chat`,
      [200],
      JSON.stringify({ text }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetCompanyChatBotSettings = (): Promise<
    MSClientResponse<CompanyChatBotSettings>
  > => {
    return Get<CompanyChatBotSettings>(
      `/${APIV1}/${CHATBOT_SETTINGS}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  UpdateChatBotAIEnabled = (
    enabled: boolean,
  ): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${APIV1}/${CHATBOT_SETTINGS}/${CHATBOT_TOGGLE_AI}`,
      [201, 204],
      JSON.stringify({ enabled }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetCompanyAddresses = (): Promise<
    MSClientResponse<{ addresses: AddressEntity[] }>
  > => {
    return Get<{ addresses: AddressEntity[] }>(
      `/${API}/${COMPANY}/address/current`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  SaveCompanyAddress = (
    data: CompanyAddress,
  ): Promise<MSClientResponse<any>> => {
    return Put<CompanyAddress, any>(
      `/${API}/${COMPANY}/save/address/current`,
      [201],
      data,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  DeleteCompanyAddress = (
    addressType: AddressTypeEnum,
  ): Promise<MSClientResponse<any>> => {
    return Delete<{ addressType: AddressTypeEnum }>(
      `/${API}/${COMPANY}/remove/address/current/${addressType}`,
      [200],
    );
  };

  CalculateEstimate = (programId: number): Promise<MSClientResponse<any>> => {
    return Post<ERCCreditEstimateResponse>(
      `/${APIV1}/${TAX_CREDITS}/${programId}/${CALCULATE_ESTIMATE}`,
      [200],
      undefined,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetRetirementCreditEstimate = (
    programId: number,
  ): Promise<MSClientResponse<{ estimates: RetirementEstimateResponse }>> => {
    return Get<{ estimates: RetirementEstimateResponse }>(
      `/${APIV1}/${PROGRAM}/${programId}/qualification-credit-estimates`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route {@link GetProgramCreditEstimatePublic}
   */
  GetProgramCreditEstimate = (
    programId: number,
  ): Promise<MSClientResponse<{ estimate: ProgramCreditEstimateResponse }>> => {
    return Get<{ estimate: ProgramCreditEstimateResponse }>(
      `/${APIV1}/${PROGRAM}/${programId}/qualification-credit-estimate`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   *  Public version of this route {@link CreateOrderFormPublic}
   */
  CreateOrderForm = (programId: number): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${APIV1}/${PROGRAM}/${programId}/${ORDER_FORM}`,
      [200],
      undefined,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  GetOrderForm = (programId: number): Promise<MSClientResponse<OrderForm>> => {
    return Get<OrderForm>(
      `/${APIV1}/${PROGRAM}/${programId}/${ORDER_FORM}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  UpdateOrderForm = (
    programId: number,
    data: Partial<OrderForm>,
  ): Promise<MSClientResponse<any>> => {
    return Put<Partial<OrderForm>, any>(
      `/${APIV1}/${PROGRAM}/${programId}/${ORDER_FORM}`,
      [201],
      data,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  UnlinkCodatConnections = (): Promise<MSClientResponse<any>> => {
    return Post<any>(
      `/${APIV1}/${ACCOUNTING}/unlink-connections`,
      [200, 201],
      JSON.stringify({}),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  CustomerIoTrackEvent = async (
    eventName: string,
    companyId: number,
  ): Promise<MSClientResponse<any>> => {
    const { res } = await Post<any>(
      `/${API}/${COMPANY}/${companyId}/${CUSTOMER_IO}`,
      [200],
      JSON.stringify({ eventName }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    return res;
  };

  AcceptTerms = async (eventName: string): Promise<MSClientResponse<any>> => {
    const { res } = await Post<any>(
      `/${APIV1}/${COMPANY}/accept-terms`,
      [200],
      JSON.stringify({ eventName }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    return res;
  };

  /**
   * Returns the documents for programs in client_review
   * Will not return hidden docs
   */
  GetAccountantDocuments = (
    taxYear: number,
    email: string,
    token: string,
  ): Promise<
    MSClientResponse<{
      documents: Document[];
    }>
  > => {
    const query = `email=${encodeURIComponent(email)}&token=${token}`;

    return Get<{ documents: Document[] }>(
      `/${API}/${DOCUMENTS}/accountant/${taxYear}?${query}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  GetFinalPaymentTotal = (
    taxYear: number,
  ): Promise<
    MSClientResponse<{
      programs: (ProgramData | undefined)[];
      totalCreditDollars: number;
      totalFeeDollars: number;
      feePercentage: number;
    }>
  > => {
    return Get<{
      programs: (ProgramData | undefined)[];
      totalCreditDollars: number;
      totalFeeDollars: number;
      feePercentage: number;
    }>(
      `/${APIV1}/${PROGRAM}/payment-totals/${taxYear}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route {@link Get6765DataPublic}
   *
   * Called on page load
   * Gets the values
   * Updates the values for boxes 5,8,11,10,29
   */
  Get6765Data = (
    programId: string | number,
  ): Promise<
    MSClientResponse<{
      formData: FormData6765;
      metadata?: { warnings?: string[] };
    }>
  > => {
    return Get<{ formData: FormData6765 }>(
      `/${API}/${PROGRAM}/${programId}/6765/form-data`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Public version of this route {@link Update6765Public}
   */
  Update6765 = (
    programId: string | number,
    req: FormData6765,
  ): Promise<MSClientResponse<{ formData: FormData6765 }>> => {
    return Post<{ formData: FormData6765 }>(
      `/${API}/${PROGRAM}/${programId}/6765/form-data`,
      [201],
      JSON.stringify(req),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res, status }) => {
      return res;
    });
  };

  /**
   * Public version of this route {@link Get3523DataPublic}
   */
  Get3523Data = (
    programId: string | number,
  ): Promise<
    MSClientResponse<{
      formData: any;
    }>
  > => {
    return Get<{ formData: any }>(
      `/${API}/${PROGRAM}/${programId}/form-3523-calcs`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  // Get Access Code estimate
  GetAccessCodeEstimate = (
    accessCode: string,
  ): Promise<
    MSClientResponse<{
      sumOfCreditCents: number;
    }>
  > => {
    return Get<{ sumOfCreditCents: number }>(
      `/${APIV1}/${COMPANY}/partner/${accessCode}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  UpdateCompanyQBData = (
    req: UpdateCompanyQBDataRequest,
  ): Promise<
    MSClientResponse<{
      company: CompanyData;
    }>
  > => {
    return Put<UpdateCompanyQBDataRequest, { company: CompanyData }>(
      `/${APIV1}/${COMPANY}/qb-data-prefill`,
      [200],
      req,
      this.wrapRequestOptions({}),
    );
  };

  // Get hygraph partner info
  GetPartnerReferral = (
    partner: string,
  ): Promise<
    MSClientResponse<{
      referral: PartnerInfo[];
    }>
  > => {
    return Get<{ referral: PartnerInfo[] }>(
      `/${APIV1}/${COMPANY}/mst-referral/${partner}`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /** COMPANY ACCESS TOKEN ROUTES */

  /**
   * Private version of this api is {@link CurrentLoggedInCompany}
   */
  CurrentCompanyByToken = async (
    accessToken: CompanyAccessToken,
  ): Promise<CompanyData | null> => {
    const requestOptions = this.wrapRequestOptions({
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-cache',
      body: JSON.stringify({
        token: accessToken.token,
        adminEmail: accessToken.adminEmail,
        scope: accessToken.scope,
      }),
    });

    return fetch(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/company-data`,
      requestOptions,
    )
      .then((res) => {
        return res.json();
      })
      .then((json: MSApiResponse<{ company: CompanyData | null }>) => {
        const { data, status } = json;

        if (status === 200 && data?.company) {
          return data.company;
        }

        return null;
      })
      .catch(() => {
        return null;
      });
  };

  ShareAssessmentPage = async (
    taxYear: number,
    page: Page,
    emailAddresses: string[],
  ): Promise<MSClientResponse<any>> => {
    const { res } = await Post<any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/tax-year/${taxYear}/survey-page/${page}`,
      [201],
      JSON.stringify({
        emailAddresses,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );

    return res;
  };

  ExpireAccessToken = async (
    taxYear: number,
    req: Page[],
  ): Promise<
    MSClientResponse<{
      updatedCompanyAccessTokens: CompanyAccessToken[] | undefined;
    }>
  > => {
    return Put<
      Page[],
      { updatedCompanyAccessTokens: CompanyAccessToken[] | undefined }
    >(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/tax-year/${taxYear}/expire`,
      [200],
      req,
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  IsTokenExpired = async (
    accessToken: CompanyAccessToken,
  ): Promise<MSClientResponse<{ isTokenExpired: boolean }>> => {
    const { res } = await Post<{ isTokenExpired: boolean }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/token/is-token-expired`,
      [200],
      JSON.stringify({
        accessToken,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );

    return res;
  };

  /**
   * Private version of this route is {@link GetSurveyQuestionGroupBySurveyName}
   */
  GetSurveyQuestionGroupBySurveyNamePublic = (
    surveyName: SurveyNameEnum,
  ): Promise<
    MSClientResponse<{
      questionGroupData: CmsQuestionGroupData;
    }>
  > => {
    return Get<{ questionGroupData: CmsQuestionGroupData }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/${SURVEY}/${surveyName}/question-group`,
      [200],
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };
  UpdateProgramCreditEstimate = (
    programId: number,
  ): Promise<MSClientResponse<any>> => {
    return Put<any, any>(
      `/${APIV1}/${PROGRAM}/${programId}/credit-estimate`,
      [200],
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Private version of this route is {@link UpdateCompanyWithSurveyResponsePublic}
   */
  UpdateCompanyWithSurveyResponsePublic = (
    accessToken: CompanyAccessToken,
    surveyName: SurveyNameEnum,
    surveyResponse: SurveyResponse,
  ): Promise<
    MSClientResponse<{
      autoqualificationStatus?: AutoqualificationStatusEnum;
    }>
  > => {
    return Put<
      any,
      {
        autoqualificationStatus?: AutoqualificationStatusEnum;
      }
    >(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/${SURVEY}/${surveyName}`,
      [200],
      {
        ...accessToken,
        taxYear: surveyResponse.taxYear,
        qualificationQuestions: surveyResponse.qualificationQuestions,
      },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   * Private version of this route is {@link GetCompanyDocumentsPublic}
   */
  GetCompanyDocumentsPublic = async (
    accessToken: CompanyAccessToken,
    req: {
      source?: string;
      documentType?: string;
    },
  ): Promise<
    MSClientResponse<{
      documents: Document[];
    }>
  > => {
    const { res } = await Post<{ documents: Document[] }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/get-documents`,
      [200],
      JSON.stringify({
        source: req.source,
        documentType: req.documentType,
        ...accessToken,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    return res;
  };

  /**
   * Private version of this route is {@link UploadCompanyDocument}
   */
  UploadCompanyDocumentPublic = async (
    accessToken: CompanyAccessToken,
    file: File | Blob | FileHandle,
    name: string,
    desc: string,
    programId?: string,
    partner?: string | null,
    taxYear?: number | null,
    documentType?: DocumentType | null,
  ): Promise<MSClientResponse<{ document: Document }>> => {
    const params: {
      document: File | Blob | FileHandle;
      name: string;
      desc: string;
      programId?: string;
      partner?: string;
      taxYear?: string;
      token: string;
      adminEmail: string;
      scope: string;
      documentType?: DocumentType;
    } = {
      document: file,
      name,
      desc,
      ...accessToken,
    };

    if (programId) params['programId'] = programId;
    if (partner) params['partner'] = partner;
    if (taxYear) params['taxYear'] = String(taxYear);
    if (documentType) params['documentType'] = documentType;

    const res = await Upload<{ document: Document }>(
      'post',
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/document`,
      [201],
      params,
      this.wrapRequestOptions({}),
    );
    return res;
  };

  /**
   * Private version of this route {@link UploadCompanyDocuments}
   *
   * Allow the upload of multiple documents at once instead of separately.
   *    * programId is optional. The company id and the documents to upload are not.
   */
  UploadCompanyDocumentsPublic = async (
    accessToken: CompanyAccessToken,
    req: UploadCompanyDocumentsRequest,
  ): Promise<MSClientResponse<{ documents: Document[] }>> => {
    const formData = new FormData();

    req.documents.forEach((document) => {
      formData.append('documents', document.file);
    });

    formData.append(
      'descriptions',
      JSON.stringify(req.documents.map((document) => document.description)),
    );

    formData.append(
      'names',
      JSON.stringify(req.documents.map((document) => document.name)),
    );

    formData.append(
      'states',
      JSON.stringify(
        req.documents.map((document) =>
          document.state ? document.state : undefined,
        ),
      ),
    );

    formData.append(
      'sources',
      JSON.stringify(
        req.documents.map((document) =>
          document.source ? document.source : undefined,
        ),
      ),
    );

    if (req.programId) {
      formData.append('programId', req.programId);
    }

    if (req.emailOps !== undefined) {
      formData.append('emailOps', req.emailOps ? 'true' : 'false');
    }

    formData.append('token', accessToken.token);
    formData.append('adminEmail', accessToken.adminEmail);
    formData.append('scope', accessToken.scope);

    return await Post<{ documents: Document[] }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/multiple-docs`,
      [201],
      formData,
      this.wrapRequestOptions({}),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   * Private version of this route {@link DeleteDocument}
   */
  DeleteDocumentPublic = async (
    accessToken: CompanyAccessToken,
    documentId: number,
  ): Promise<MSClientResponse<any>> => {
    return await Post<{ document: Document }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/delete-document`,
      [200],
      JSON.stringify({
        ...accessToken,
        documentId: documentId,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   * Private version of this route {@link GetProgram}
   */
  GetProgramPublic = async (
    accessToken: CompanyAccessToken,
    programId: string | number,
  ): Promise<
    MSClientResponse<{
      program: ProgramData;
    }>
  > => {
    return await Post<{ program: ProgramData }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/program`,
      [200],
      JSON.stringify({
        ...accessToken,
        programId,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   * Private version of this route {@link UpdateProgram}
   */
  UpdateProgramPublic = (
    accessToken: CompanyAccessToken,
    programId: string | number,
    req: UpdateProgramRequest,
  ): Promise<MSClientResponse<{ updatedProgram: ProgramData }>> => {
    return Put<UpdateProgramRequest, { updatedProgram: ProgramData }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/program/${programId}`,
      [200],
      {
        ...accessToken,
        ...req,
      },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   * Private version of this route is {@link UpdatePrograms}
   */
  UpdateProgramsPublic = (
    accessToken: CompanyAccessToken,
    req: UpdateProgramsRequest,
  ): Promise<MSClientResponse<{ programs: ProgramData[] }>> => {
    return Put<UpdateProgramsRequest, { programs: ProgramData[] }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/programs`,
      [200],
      {
        ...accessToken,
        ...req,
      },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   * Private version of this route {@link GetRdVendorExpenses}
   */
  GetRdVendorExpensesPublic = (
    accessToken: CompanyAccessToken,
    programId: number,
  ): Promise<MSClientResponse<RdVendorExpense[]>> => {
    return Post<RdVendorExpense[]>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/program/get-rd-vendor-expenses`,
      [200],
      JSON.stringify({
        ...accessToken,
        programId,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   * Private version of this route {@link PostRdVendorExpenses}
   */
  PostRdVendorExpensesPublic = (
    accessToken: CompanyAccessToken,
    programId: number,
    vendor: RdVendorExpense,
  ): Promise<MSClientResponse<RdVendorExpense>> => {
    return Post<RdVendorExpense>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/rd-vendor-expenses`,
      [201],
      JSON.stringify({
        ...accessToken,
        ...vendor,
        programId: programId,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  /**
   *  Private version of this route {@link PutRdVendorExpenses}
   */
  PutRdVendorExpensesPublic = (
    accessToken: CompanyAccessToken,
    programId: number,
    vendor: RdVendorExpense,
  ): Promise<MSClientResponse<RdVendorExpense>> => {
    return Put<RdVendorExpenseWithAccessToken, RdVendorExpense>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/rd-vendor-expense/${vendor.id}`,
      [200],
      {
        ...accessToken,
        ...vendor,
        programId,
      },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then((res) => res);
  };

  /**
   *  Private version of this route {@link PutRdVendorExpenseReceipts}
   */
  PutRdVendorExpenseReceiptsPublic = (
    accessToken: CompanyAccessToken,
    programId: number,
    vendor: RdVendorExpense,
    docIdsToAttach: number[],
  ): Promise<MSClientResponse<any>> => {
    const docIds = docIdsToAttach.map((docId) => ({
      documentId: docId,
    }));
    return Put<any, any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/rd-vendor-expense/${vendor.id}/receipts`,
      [200],
      {
        programId,
        ...accessToken,
        docIds: docIds,
      },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   *  Private version of this route {@link DeleteRdVendorExpense}
   */
  DeleteRdVendorExpensePublic = (
    accessToken: CompanyAccessToken,
    programId: number,
    vendor: RdVendorExpense,
  ): Promise<MSClientResponse<any>> => {
    return Post<RdVendorExpense>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/rd-vendor-expense/${vendor.id}`,
      [200],
      JSON.stringify({
        ...accessToken,
        ...vendor,
        programId: programId,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  /**
   *  Private version of this route {@link DeleteAttachmentsFromVendorExpense}
   */
  DeleteAttachmentsFromVendorExpensePublic = (
    accessToken: CompanyAccessToken,
    programId: number,
    vendorId: number,
    docIds: number[],
  ): Promise<MSClientResponse<{ deletedIds: number[] }>> => {
    return Put<any, any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/rd-vendor-expense/${vendorId}/receipts:bulkDelete`,
      [200, 201],
      {
        ...accessToken,
        docIds,
        programId,
      },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   *  Private version of this route {@link PrefillWorkDone}
   */
  PrefillWorkDonePublic = (
    accessToken: CompanyAccessToken,
    taxYear: number,
  ) => {
    return Post<{ employmentRecords: EmploymentRecordWithWorkDoneData[] }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/work-done/pull-forward-prefill`,
      [201],
      JSON.stringify({ ...accessToken, taxYear }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => {
      return res;
    });
  };

  /**
   *  Private version of this route {@link CreateEmploymentRecordForUserCompany}
   */
  CreateEmploymentRecordForUserCompanyPublic = (
    accessToken: CompanyAccessToken,
    createRequest: CreateEmploymentRecordRequest,
  ): Promise<MSClientResponse<EmploymentRecordDataWithWorkDoneData>> => {
    return Post<EmploymentRecordDataWithWorkDoneData>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/employment-record/add-employee-to-user-company`,
      [201],
      JSON.stringify({
        ...accessToken,
        ...createRequest,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  /**
   * Private version of this route {@link UpdateMissingEmployeeInformation}
   */
  UpdateMissingEmployeeInformationPublic = (
    accessToken: CompanyAccessToken,
    recordId: number | string,
    updateRequest: UpdateMissingInformationEmploymentRecordRequest,
  ): Promise<
    MSClientResponse<{ employmentRecordData: EmploymentRecordData }>
  > => {
    return Put<
      UpdateMissingInformationEmploymentRecordRequest,
      { employmentRecordData: EmploymentRecordData }
    >(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/employment-record/update-missing-fields/${recordId}`,
      [200],
      {
        ...accessToken,
        ...updateRequest,
      },
      this.wrapRequestOptions({}),
    );
  };

  /**
   * Private version of this route {@link UpdateExpenseClassification}
   */
  UpdateExpenseClassificationPublic = (
    accessToken: CompanyAccessToken,
    updates: UpdateWorkDoneRequest[],
  ): Promise<
    MSClientResponse<{
      updatedWorkDone: WorkDoneData[];
    }>
  > => {
    return Put<
      {
        updates: UpdateWorkDoneRequest[];
      },
      {
        updatedWorkDone: WorkDoneData[];
      }
    >(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/work-done/classification`,
      [200],
      {
        updates,
        ...accessToken,
      },
      this.wrapRequestOptions({}),
    ).then((res) => {
      if (!res.data) {
        return res;
      }
      const { updatedWorkDone } = res.data;
      if (
        updatedWorkDone.filter(this.ValidateWorkDoneData).length !==
        updatedWorkDone.length
      ) {
        // TODO: This should return an error code, not just a message
        return {
          errorMsg: `An error occurred loading. Please reach out to contact${AT_MS_DOMAIN}`,
        };
      }

      return res;
    });
  };

  /**
   * Private version of this route {@link  UpdateCompanyEventAttestation}
   */
  UpdateCompanyEventAttestationPublic = (
    accessToken: CompanyAccessToken,
    eventType: AttestationEventType,
    taxYear: number,
  ): Promise<MSClientResponse<any>> => {
    return Put<
      {
        eventType: AttestationEventType;
        taxYear: number;
        adminEmail: string;
        token: string;
        scope: string;
      },
      any
    >(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/event-attestation`,
      [201],
      {
        taxYear,
        eventType,
        ...accessToken,
      },
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   * Private version of this route {@link Get6765Data}
   */
  Get6765DataPublic = async (
    accessToken: CompanyAccessToken,
    programId: string | number,
  ): Promise<
    MSClientResponse<{
      formData: FormData6765;
      metadata?: { warnings?: string[] };
    }>
  > => {
    return Post<any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/program/${programId}/6765-form-data`,
      [200],
      JSON.stringify(accessToken),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  /**
   * Private version of this route {@link Update6765}
   */
  Update6765Public = (
    accessToken: CompanyAccessToken,
    programId: string | number,
    req: FormData6765,
  ): Promise<MSClientResponse<{ formData: FormData6765 }>> => {
    return Put<any, any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/program/${programId}/6765-form-data`,
      [201],
      JSON.stringify({
        ...accessToken,
        ...req,
      }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  /**
   * Private version of this route {@link Get3523Data}
   */
  Get3523DataPublic = async (
    accessToken: CompanyAccessToken,
    programId: string | number,
  ): Promise<
    MSClientResponse<{
      formData: any;
    }>
  > => {
    return Post<any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/program/${programId}/form-3523-calcs`,
      [200],
      JSON.stringify(accessToken),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ).then(({ res }) => res);
  };

  /**
   * Private version of this route {@link GetProgramCreditEstimate}
   */
  GetProgramCreditEstimatePublic = async (
    accessToken: CompanyAccessToken,
    programId: number,
  ): Promise<MSClientResponse<{ estimate: ProgramCreditEstimateResponse }>> => {
    const { res } = await Post<{ estimate: ProgramCreditEstimateResponse }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/program/${programId}/qualification-credit-estimate`,
      [200],
      JSON.stringify({ accessToken }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    return res;
  };

  /**
   *  Private version of this route {@link CreateOrderForm}
   */
  CreateOrderFormPublic = async (
    accessToken: CompanyAccessToken,
    programId: number,
  ): Promise<MSClientResponse<any>> => {
    const { res } = await Post<any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/program/${programId}/${ORDER_FORM}`,
      [200],
      JSON.stringify({ accessToken }),
      this.wrapRequestOptions({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    return res;
  };

  /**
   * Private version of this route {@link GetRDProjects}
   */
  GetRDProjectsPublic = async (
    accessToken: CompanyAccessToken,
    programId: string | number,
  ): Promise<
    MSClientResponse<{
      rdProjects: RdProject[];
    }>
  > => {
    const { res } = await Post<{
      rdProjects: RdProject[];
    }>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/${PROGRAM}/${programId}/${RD_PROJECTS}`,
      [200],
      JSON.stringify({ accessToken }),
      this.addSharedHeaders({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    return res;
  };

  /**
   * Private version of this route {@link CreateRDProject}
   */
  CreateRDProjectPublic = async (
    accessToken: CompanyAccessToken,
    programId: string | number,
    project: CreateRdProject,
  ): Promise<MSClientResponse<RdProject>> => {
    const { res } = await Post<any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/${PROGRAM}/${programId}/${RD_PROJECTS}/create`,
      [201],
      JSON.stringify({ project, accessToken }),
      this.addSharedHeaders({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    return res;
  };

  /**
   * Private version of this route {@link UpdateCompany}
   */
  UpdateYearFoundedPublic = (
    accessToken: CompanyAccessToken,
    yearFounded: number,
  ): Promise<MSClientResponse<any>> => {
    return Put<any, any>(
      `/${APIV1}/${COMPANY_ACCESS_TOKEN}/${COMPANY}/year-founded`,
      [200],
      { ...accessToken, yearFounded },
      this.addSharedHeaders({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  GetTermsByNames = async (names: string[]) => {
    return await Get<any>(`/${APIV1}/${TERMS}?names=${names.join(',')}`, [200]);
  };

  GetRecentTermsAcceptanceByCompanyAndTermsId = async (termsId: string) => {
    return await Get<any>(
      `/${APIV1}/${TERMS}/${ACCEPTANCE}?termsId=${termsId}`,
      [200],
      this.addSharedHeaders({}),
    );
  };

  CaptureTermsAcceptance = async (
    termsNames: string[],
  ): Promise<MSClientResponse<RdProject>> => {
    const { res } = await Post<any>(
      `/${APIV1}/${TERMS}/accept-terms`,
      [201],
      JSON.stringify({ termsNames }),
      this.addSharedHeaders({
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
    return res;
  };

  GetTermsAcceptanceByNames = async (names: string[]) => {
    return await Get<any>(
      `/${APIV1}/${TERMS}/current-acceptance?names=${names.join(',')}`,
      [200],
      this.addSharedHeaders({}),
    );
  };
}

/**
 * While we are transitioning to Auth0, feel free to use this client
 * as a way to get an instance of ServerClient
 *
 * PLEASE PREFER using the Auth0FeatureContext injected client if you
 * are writing code in a JSX context:
 *
 * @example
 * const { client } = useContext(Auth0FeatureContext)
 * client.SendInvitation() ...
 */
export const EmptyClient = new ServerClient();
