import React from 'react';
import { observer } from 'mobx-react';
import { useCommonStores, useFeatureFlags } from 'stores/useStores';
import { Page } from 'lib/constants';
import { TaxCreditPageBody } from './TaxCreditPageBody';
import { Button } from 'component-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  claimButtonCta: {
    maxWidth: 150,
  },
}));

type PrepareForNextTaxYearPros = {
  taxYear: number;
};

export const PrepareForNextTaxYear = observer(
  ({ taxYear }: PrepareForNextTaxYearPros) => {
    const classes = useStyles();
    const { app } = useCommonStores();
    const featureFlags = useFeatureFlags();
    const NEXT_TAX_YEAR = taxYear;

    const claimBannerTitle = `Save more money, all year long`;

    const claimBannerSubtitle = `Discover MainStreet's latest range of benefits–unlock hidden tax savings for you and your employees.`;

    const claimBannerButtonCta = featureFlags.showSharedSavings ? (
      <Button
        className={classes.claimButtonCta}
        label='Learn more'
        variant='outlined'
        onClick={() => app.history.push(`${Page.sharedSavings}`)}
      />
    ) : undefined;

    const bodyTitle = `Prepare for ${NEXT_TAX_YEAR} Tax Credits`;

    const bodySubtitle = `Get started by connecting your payroll account. As we approach the end of ${NEXT_TAX_YEAR}, we'll contact you with instructions for tax credit calculations.`;

    return (
      <TaxCreditPageBody
        claimBannerTitle={claimBannerTitle}
        claimBannerSubtitle={claimBannerSubtitle}
        claimBannerButtonCta={claimBannerButtonCta}
        bodyTitle={bodyTitle}
        bodySubtitle={bodySubtitle}
      />
    );
  },
);
