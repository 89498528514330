import { observer } from 'mobx-react';
import {
  useCommonStores,
  useCompany,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import { SurveyAnswer, useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  AttestationEventTypeEnum,
  CompanyDetailsGraphCmsQuestionIds,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  Page,
  ProgramNameEnum,
  Programs,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  QualificationStatusEnum,
  SubGroupNameEnum,
  SurveyNameEnum,
} from 'lib/constants';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CmsQuestionData,
  CmsRenderTree,
  CmsRenderTreeShowCondition,
  CmsRenderTreeShowConditions,
  CmsRenderTreeSubgroup,
  Document,
  DocumentSourceEnum,
  DocumentForUpload,
} from 'lib/interfaces';
import {
  ExpandableSurveyQuestion,
  SurveyAttestation,
} from 'pages/tax-processing/expense-classification/components/';
import ReviewQualificationQuestions from 'pages/tax-processing/expense-classification/components/company-details/ReviewQualificationQuestions';
import TotalIncomeGrossReceiptsHelpDrawer from 'pages/tax-processing/expense-classification/components/company-details/TotalIncomeGrossReceiptsHelpDrawer';
import OtherCreditsHelpDrawer from 'pages/tax-processing/expense-classification/components/company-details/OtherCreditsHelpDrawer';
import GeorgiaRdGrossReceiptsHelpDrawer from 'pages/tax-processing/expense-classification/components/company-details/GeorgiaRdGrossReceiptsHelpDrawer';
import { Loading } from 'components/util/Loading';
import {
  AnswerType,
  Color,
  Content,
  Delay,
  Divider,
  FileUpload,
  Flex,
  SurveyQuestion,
  Text,
  FileHandle,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { renderTaxYearInQuestionText } from 'lib/useQuestionGroup';
import { useExpenseClassificationAnswerPrefill } from 'lib/useExpenseClassificationAnswerPrefill';
import { IsRDCreditProgram, useEffectOnce } from 'lib/helpers';
import { getAlertBySubGroup } from 'pages/tax-processing/expense-classification/ExpenseClassification';
import { datadogLogs } from '@datadog/browser-logs';
import TaxReturnUploadQuestion from 'pages/tax-processing/expense-classification/components/company-details/TaxReturnUploadQuestion';
import DOMPurify from 'dompurify';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { PrefillAlert } from '../../components/PrefillAlert';
import { logContext } from 'logging';
import { SaveSurveyAttestation } from 'pages/tax-processing/expense-classification/components/expense-classification/SurveyAttestation';
import { QualificationStatusModal } from '../../../retirement-plan/components';
import LoadingWidget from 'components/util/LoadingWidget';
import renderTreeJson from '../../../../../../hygraph/renderTree/unifiedRdCompanyDetails.json';

const logger = datadogLogs.createLogger('UnifiedRdCompanyDetails');

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: Color.neutral.white,
  },
  mainContent: {
    width: '100%',
    margin: '0 auto',
    padding: '0 0 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
  surveyListText: {
    '& > ul': {
      margin: 0,
      padding: '0 24px',

      '& > li': {
        paddingBottom: '8px',
        fontSize: '15px',
        lineHeight: '24px',
        color: Color.neutral._80,
      },
    },
  },
  surveyQuestion: {
    '& button': {
      padding: '12px 15px',
    },
  },
}));

interface UnifiedRdCompanyDetailsProps {
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
  onError: () => void;
}

interface SubGroupTitle {
  title?: string;
  subtitle?: string;
}

const GRANT_SPENDING_DETIALS = 'Grant spending details';
const GRANT_CONTRACT = 'Grant Contract';

type GrantDescription = typeof GRANT_SPENDING_DETIALS | typeof GRANT_CONTRACT;

interface GrantDocumentsForUpload extends DocumentForUpload {
  description: GrantDescription;
}

export const UnifiedRdCompanyDetails: React.FC<UnifiedRdCompanyDetailsProps> =
  observer(
    ({ taxYear, onError, onNext, onBack }: UnifiedRdCompanyDetailsProps) => {
      const classes = useStyles();
      const { surveyFlow, unifiedTaxCredits } = useTaxCreditsStores();
      const { company } = useCompany();
      const { client } = useLegacyClients();
      const { app, auth, chatbot, companyStore } = useCommonStores();
      const featureFlags = useFeatureFlags();
      const [loading] = useState<boolean>(true);
      const [showDqModal, setShowDqModal] = useState<boolean>(false);
      const program =
        company.programs.find(
          (item) =>
            item.taxYear === taxYear &&
            item.name === ProgramNameEnum.FED_RD_TAX,
        ) || null;
      const [renderQuestionOverrides, setRenderQuestionOverrides] = useState<
        { [key: string]: () => boolean } | undefined
      >(undefined);
      const [hasConfirmedAnswers, setHasConfirmedAnswers] =
        useState<boolean>(false);
      const [disqualifyingQuestions, setDisqualifyingQuestions] =
        useState<boolean>(false);
      const [documents, setDocuments] = useState<Document[] | null>(null);
      const [subsidiariesPerformRD, setSubsidiariesPerformRD] =
        useState<boolean>(false);
      const [acquireesPerformRD, setAcquireesPerformRD] =
        useState<boolean>(false);

      const grantSpendingFiles: string[] = useMemo(
        () =>
          documents
            ?.filter(
              (document) =>
                document.name &&
                document.description &&
                GRANT_SPENDING_DETIALS === document.description,
            )
            .map((document) => document.name)
            .filter(
              (documentName) => typeof documentName === 'string',
            ) as string[],
        [documents],
      );

      const grantContractFiles: string[] = useMemo(
        () =>
          documents
            ?.filter(
              (document) =>
                document.name &&
                document.description &&
                GRANT_CONTRACT === document.description,
            )
            .map((document) => document.name)
            .filter(
              (documentName) => typeof documentName === 'string',
            ) as string[],
        [documents],
      );

      const [filesToUpload, setFilesToUpload] = useState<
        GrantDocumentsForUpload[]
      >([]);

      const surveyName: SurveyNameEnum =
        SurveyNameEnum.UNIFIED_RD_COMPANY_DETAILS_SURVEY;

      const [reInitCompanyQualQuestions, setReInitCompanyQualQuestions] =
        useState(0);

      const {
        isLoading,
        questionsToRender,
        surveyAnswers,
        addSurveyAnswers,
        addSurveyAnswersWithoutRerender,
        getQuestionData,
        isSurveyComplete,
        saveAnswers,
        getSubGroupRenderData,
      } = useSurveyQuestions(
        surveyName,
        taxYear,
        {
          showConditionOverrides: renderQuestionOverrides,
        },
        renderTreeJson as CmsRenderTree,
        reInitCompanyQualQuestions,
      );

      useExpenseClassificationAnswerPrefill(
        surveyName,
        program,
        addSurveyAnswersWithoutRerender,
      );

      // Make sure to only refresh program when the ID changes
      const [reviewComponentEditMode, setReviewComponentEditMode] =
        useState<boolean>(true);
      const [taxFormUploadEditMode, setTaxFormUploadEditMode] =
        useState<boolean>(true);
      const [expandedQuestionIds, setExpandedQuestionIds] = useState<
        Set<string>
      >(new Set());

      const handleQuestionExpansion = (
        questionId: string,
        expanded: boolean,
      ): void => {
        if (expandedQuestionIds.has(questionId) && !expanded) {
          setExpandedQuestionIds((prev) => {
            const newState = new Set(prev);
            newState.delete(questionId);
            return newState;
          });
        } else if (!expandedQuestionIds.has(questionId) && expanded) {
          setExpandedQuestionIds((prev) => new Set(prev.add(questionId)));
        }
      };

      useEffect(() => {
        // Check for any questions that are no longer in the
        // render tree, but are still marked as "expanded" in state.
        const leftoverExpandedQuestions = new Array(
          ...expandedQuestionIds,
        ).filter((id) => !questionsToRender[id]);

        if (leftoverExpandedQuestions.length > 0) {
          // Remove those questions from "expanded" state
          setExpandedQuestionIds((prev) => {
            const newState = new Set(prev);
            leftoverExpandedQuestions.forEach((questionId) =>
              newState.delete(questionId),
            );
            return newState;
          });
        }
      }, [questionsToRender]);

      const handleFileUploads = async () => {
        const uploadCompanyDocuments = companyStore.accessToken
          ? client.UploadCompanyDocumentsPublic(companyStore.accessToken, {
              documents: filesToUpload,
              emailOps: true,
            })
          : client.UploadCompanyDocuments({
              documents: filesToUpload,
              emailOps: true,
            });

        const { errorMsg } = await uploadCompanyDocuments;

        if (errorMsg) {
          logger.error(
            `UnifiedRdCompanyDetails file upload error for companyId ${company.id}: ${errorMsg}.`,
          );
        } else {
          setFilesToUpload([]);
        }
      };

      // pass in a setter
      const onFileAdded = (description: GrantDescription) => {
        return (fileHandle: FileHandle) => {
          const { file, name } = fileHandle;

          const fileToUpload = {
            file,
            description,
            name,
            source: DocumentSourceEnum.CLIENT,
          };

          filesToUpload.push(fileToUpload);
          setFilesToUpload(filesToUpload);
        };
      };

      const handleFileRemoval = (fileHandle: FileHandle) => {
        const filteredFiles = filesToUpload.filter(
          (file) => file.name !== fileHandle.name,
        );
        if (filteredFiles.length !== filesToUpload.length) {
          setFilesToUpload(filteredFiles);
        }
      };

      const handlePasswordProtectedError = (
        errorMessage: string,
        filesWErrors: string[],
      ) => {
        logger.warn(
          `UnifiedRdCompanyDetails file upload error for companyId ${company.id}: ${errorMessage}.`,
        );

        const filteredFiles = filesToUpload.filter(
          (file) => !filesWErrors.includes(file.name),
        );
        if (filteredFiles.length !== filesToUpload.length) {
          setFilesToUpload(filteredFiles);
        }
      };

      const onContinue = async () => {
        await handleFileUploads();
        if (companyStore.accessToken) {
          unifiedTaxCredits.setShowInviteeSuccessModal(true);
        }
        surveyFlow.setSurveyContinueLoading(true);
        // Submit answers
        await saveAnswers().catch(() => onError());

        await SaveSurveyAttestation({
          hasConfirmedAnswers,
          eventType:
            AttestationEventTypeEnum.YEAR_END_ASSESSMENT_COMPANY_DETAILS_COMPLETE,
          userEmail: auth.user?.email || company?.adminEmail,
          taxYear: program!.taxYear,
          companyId: company.id,
          client,
          accessToken: companyStore.accessToken,
        });

        if (disqualifyingQuestions) {
          surveyFlow.updateStatus({
            priority: 1,
            status: QualificationModalStatusEnum.UNIFIED_RD_DQ,
            programName: ProgramNameEnum.FED_RD_TAX,
          });
          surveyFlow.setModalContinueIsLoading(false);
          if (companyStore.accessToken) {
            await surveyFlow.updateProgramStageStatus(
              ProgramNameEnum.FED_RD_TAX,
              taxYear,
              ProgramStageEnum.DISQUALIFIED,
              QualificationStatusEnum.DISQUALIFIED,
              featureFlags.showSurveyReviewPage
                ? ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT
                : null,
              companyStore.accessToken,
            );
          } else {
            surveyFlow.setShowQualificationStatusModal(true);
            setShowDqModal(true);
          }
        } else {
          // only update stage if the last question of RD business details is answered
          if (
            company.qualificationQuestionsByYear?.[taxYear][
              GraphCmsQuestionIdEnum.TIME_TRACKING
            ] !== undefined
          ) {
            await surveyFlow.updateProgramStageStatus(
              ProgramNameEnum.FED_RD_TAX,
              taxYear,
              ProgramStageEnum.EXPENSE_CLASSIFICATION,
              QualificationStatusEnum.QUALIFICATION_IN_PROGRESS,
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES,
              companyStore.accessToken,
            );
          }

          !companyStore.accessToken && onNext();
        }
        surveyFlow.setSurveyContinueLoading(false);
      };

      const continueText = companyStore.accessToken
        ? 'Finished'
        : 'Continue to R&D Tax credit: Supplies & Services';

      const getSubGroupTitle = (
        showConditions: CmsRenderTreeShowCondition[],
      ): SubGroupTitle => {
        const [operator, condition] = Object.entries(showConditions)[0];
        if (operator in titleByOperator) {
          return titleByOperator[operator](condition);
        }
        return {
          title: '',
        };
      };

      const titleByOperator: Record<string, (...args: any[]) => SubGroupTitle> =
        {
          hasProgram: ({ programName }: { programName: ProgramNameEnum }) => {
            return {
              title: `Specific details for your ${Programs[programName].display} program.`,
            };
          },

          equals: ({
            questionId,
            answerValue,
          }: {
            questionId: string;
            answerValue: string;
          }) => {
            const question = getQuestionData(questionId as string);
            if (question) {
              const taxYear = program
                ? program.taxYear
                : new Date().getUTCFullYear(); // Every program should have a tax year
              question.text = renderTaxYearInQuestionText(
                question.text,
                taxYear,
              );
            }
            const answerText = question?.answerIDs.find(
              (a) => a.id === answerValue,
            )?.text;
            if (question && answerText) {
              return {
                title: `Because you answered "${
                  question.answerIDs.find((a) => a.id === answerValue)?.text
                }" to "${question.text}"`,
              };
            }
            return {
              title: '',
            };
          },

          and: (conditions: CmsRenderTreeShowConditions) => {
            // create an array from the conditions object so we can search through it
            const conditionArray = Object.entries(conditions);

            // find the condition with the answer that matches the answer given in the survey
            const condition = conditionArray.find(
              (c: any) =>
                surveyAnswers[c[1]?.equals?.questionId] ===
                c[1]?.equals?.answerValue,
            );

            // return the found condition or default to the first condition
            return getSubGroupTitle(condition ? condition[1] : conditions[0]);
          },

          or: (conditions: CmsRenderTreeShowConditions) => {
            const conditionArray = Object.entries(conditions);
            const condition = conditionArray.find(
              (c: any) =>
                surveyAnswers[c[1]?.equals?.questionId] ===
                c[1]?.equals?.answerValue,
            );
            return getSubGroupTitle(condition ? condition[1] : conditions[0]);
          },
        };

      const titleBySubGroupId = (id: string): SubGroupTitle | null => {
        // HACK - Allow manual setting of title for edge-case subgroups.
        switch (id) {
          case 'extraDetails':
            return {
              title: 'Please confirm some extra details about your company.',
              subtitle:
                'We need to confirm a few more things to accurately generate your tax forms',
            };
          case 'incomeAndResearchSpend':
            return {
              title: `Company's income and research spend`,
              subtitle:
                'We will need this extra information to calculate your credits based on your previous responses.',
            };
          default:
            return null;
        }
      };

      const getTitleBySubGroup = (
        subGroupName: string,
        subGroup: CmsRenderTreeSubgroup | undefined,
      ): SubGroupTitle => {
        if (subGroup) {
          if (typeof subGroup.showCondition === 'boolean') {
            return {};
          }

          const subTitleById = titleBySubGroupId(subGroupName);

          if (subTitleById) {
            return subTitleById;
          }

          const [operator, conditions] = Object.entries(
            subGroup.showCondition,
          )[0];
          return titleByOperator[operator](conditions);
        }
        return {};
      };

      const onSubQuestionsComplete = (
        question: CmsQuestionData,
        subGroupName: string,
      ) => {
        const subQuestionAnswers = question.subQuestions
          ?.filter((sq) => sq.answerValue != null)
          .map((subQuestion) => ({
            questionId: subQuestion.id,
            answerValue: subQuestion.answerValue!,
          }));
        if (subQuestionAnswers) {
          addSurveyAnswers(question.id, subGroupName, subQuestionAnswers);
        }
        handleQuestionExpansion(question.id, false);
      };

      const renderQualificationQuestionsReviewComponent = (
        question: CmsQuestionData,
        subGroupName: string,
      ) => (
        <ExpandableSurveyQuestion
          questionProps={question}
          editModeOverride={reviewComponentEditMode}
          setEditModeOverride={setReviewComponentEditMode}
          key={question.id}
          saveAnswers={saveAnswers}
          chatbot={chatbot}
        >
          <ReviewQualificationQuestions
            questionProps={question}
            onComplete={() => {
              onSubQuestionsComplete(question, subGroupName);
              setReviewComponentEditMode(false);
            }}
            program={program}
          />
        </ExpandableSurveyQuestion>
      );

      const renderTaxReturnUploadQuestion = (
        question: CmsQuestionData,
        subGroupName: string,
      ) => (
        <ExpandableSurveyQuestion
          questionProps={question}
          editModeOverride={taxFormUploadEditMode}
          setEditModeOverride={setTaxFormUploadEditMode}
          helpDrawerContent={<TotalIncomeGrossReceiptsHelpDrawer />}
          key={question.id}
          saveAnswers={saveAnswers}
          chatbot={chatbot}
        >
          {program && documents !== null ? (
            <TaxReturnUploadQuestion
              key={question.id}
              program={program}
              setEditMode={setTaxFormUploadEditMode}
              onComplete={(completedQuestion: CmsQuestionData) => {
                onSubQuestionsComplete(completedQuestion, subGroupName);
                setTaxFormUploadEditMode(false);
                handleQuestionExpansion(question.id, false);
              }}
              getQuestionProps={question}
              saveAnswers={saveAnswers}
              documents={documents}
            />
          ) : (
            <Flex padding={[0, 0, 48, 0]}>
              <LoadingWidget />
            </Flex>
          )}
        </ExpandableSurveyQuestion>
      );

      const handleOnDisqualified = async () => {
        // TODO: skip the modal if an accessToken is present
        surveyFlow.setModalContinueIsLoading(true);

        await surveyFlow
          .updateProgramStageStatus(
            ProgramNameEnum.FED_RD_TAX,
            taxYear,
            ProgramStageEnum.DISQUALIFIED,
            QualificationStatusEnum.DISQUALIFIED,
            featureFlags.showSurveyReviewPage
              ? ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT
              : null,
          )
          .then(() => {
            surveyFlow.setModalContinueIsLoading(false);
            surveyFlow.setShowQualificationStatusModal(false);
            setShowDqModal(false);
            // don't use OnNext because we want to skip the next page and go straight to disabled access
            app.history.push(
              `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.disabledAccess}`,
            );
          });

        logger.info(
          `[${taxYear} Assessment]: ${company.id} DISQUALIFIED in R&D Company Details survey - "No" to 4 part test`,
          logContext({
            company,
          }),
        );
      };

      interface SubsidiaryQreQuestionProps {
        question: CmsQuestionData;
        subGroupName: string;
      }

      const renderSubsidiaryQreQuestion = ({
        question,
        subGroupName,
      }: SubsidiaryQreQuestionProps): JSX.Element => {
        return (
          <ExpandableSurveyQuestion
            questionProps={question}
            onQuestionPropsChange={(answers: SurveyAnswer[]) =>
              addSurveyAnswers(question.id, subGroupName, answers)
            }
            key={question.id}
            onEditModeToggle={handleQuestionExpansion}
            saveAnswers={saveAnswers}
            chatbot={chatbot}
          />
        );
      };

      const renderAcquisitionQreQuestion = ({
        question,
        subGroupName,
      }: SubsidiaryQreQuestionProps): JSX.Element => {
        return (
          <ExpandableSurveyQuestion
            questionProps={question}
            onQuestionPropsChange={(answers: SurveyAnswer[]) =>
              addSurveyAnswers(question.id, subGroupName, answers)
            }
            key={question.id}
            onEditModeToggle={handleQuestionExpansion}
            saveAnswers={saveAnswers}
            chatbot={chatbot}
          />
        );
      };

      const renderQuestionContent = (
        question: CmsQuestionData,
        subGroupName: string,
      ): JSX.Element => {
        let doesNotRetainResearchRights = false;
        let hasNoFinancialLossRisk = false;
        let willUploadGrantContract = false;

        if (
          question.id === GraphCmsQuestionIdEnum.RETAIN_GRANT_RESEARCH_RIGHTS
        ) {
          if (
            question.answerValue ===
            GraphCmsQuestionIdToAnswers[
              GraphCmsQuestionIdEnum.RETAIN_GRANT_RESEARCH_RIGHTS
            ].NO
          ) {
            doesNotRetainResearchRights = true;
          } else if (
            question.answerValue ===
            GraphCmsQuestionIdToAnswers[
              GraphCmsQuestionIdEnum.RETAIN_GRANT_RESEARCH_RIGHTS
            ].YES
          ) {
            doesNotRetainResearchRights = false;

            if (
              question.answerValue ===
              GraphCmsQuestionIdToAnswers[
                GraphCmsQuestionIdEnum.FINANCIAL_LOSS_RISK
              ].YES
            ) {
              const filteredFiles = filesToUpload.filter(
                (file) => file.description !== GRANT_SPENDING_DETIALS,
              );
              if (filteredFiles.length !== filesToUpload.length) {
                setFilesToUpload(filteredFiles);
              }
            }
          }
        }

        if (question.id === GraphCmsQuestionIdEnum.FINANCIAL_LOSS_RISK) {
          if (
            question.answerValue ===
            GraphCmsQuestionIdToAnswers[
              GraphCmsQuestionIdEnum.FINANCIAL_LOSS_RISK
            ].NO
          ) {
            hasNoFinancialLossRisk = true;
          } else if (
            question.answerValue ===
            GraphCmsQuestionIdToAnswers[
              GraphCmsQuestionIdEnum.FINANCIAL_LOSS_RISK
            ].YES
          ) {
            hasNoFinancialLossRisk = false;

            if (
              question.answerValue ===
              GraphCmsQuestionIdToAnswers[
                GraphCmsQuestionIdEnum.RETAIN_GRANT_RESEARCH_RIGHTS
              ].YES
            ) {
              const filteredFiles = filesToUpload.filter(
                (file) => file.description !== GRANT_SPENDING_DETIALS,
              );
              if (filteredFiles.length !== filesToUpload.length) {
                setFilesToUpload(filteredFiles);
              }
            }
          }
        }

        if (question.id === GraphCmsQuestionIdEnum.GRANT_CONTRACT_AVAILABLE) {
          if (
            question.answerValue ===
            GraphCmsQuestionIdToAnswers[
              GraphCmsQuestionIdEnum.GRANT_CONTRACT_AVAILABLE
            ].YES
          ) {
            willUploadGrantContract = true;
          } else if (
            question.answerValue ===
            GraphCmsQuestionIdToAnswers[
              GraphCmsQuestionIdEnum.GRANT_CONTRACT_AVAILABLE
            ].NO
          ) {
            willUploadGrantContract = false;

            const filteredFiles = filesToUpload.filter(
              (file) => file.description !== GRANT_CONTRACT,
            );
            if (filteredFiles.length !== filesToUpload.length) {
              setFilesToUpload(filteredFiles);
            }
          }
        }

        question.text = renderTaxYearInQuestionText(question.text, taxYear);
        const subtitleList =
          question.subtitle && question.subtitle.includes('<ul>');
        if (subtitleList) {
          return (
            <SurveyQuestion
              className={classes.surveyQuestion}
              key={question.id}
              placeholder={question.placeholder}
              text={question.text}
              subtitle={
                subtitleList ? (
                  <div
                    className={classes.surveyListText}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(question.subtitle),
                    }}
                  />
                ) : (
                  question.subtitle
                )
              }
              questionTextSize={15}
              answerType={question.answerType as AnswerType}
              answerValue={question.answerValue}
              onChange={(answer) => {
                question.answerValue = answer;
                addSurveyAnswers(question.id, subGroupName, [
                  { questionId: question.id, answerValue: answer },
                ]);
              }}
              answerOptions={question.answerIDs}
              withCard={false}
              multiSelectDropdown
            />
          );
        }

        if (
          question.id === CompanyDetailsGraphCmsQuestionIds.OTHER_CREDIT_CLAIMED
        ) {
          const ERC_ANSWER_ID = 'ckzg00ea07igx0d69e0qgylqf';
          const showERC = !company.programs.some(
            (p) =>
              IsRDCreditProgram(p.name) &&
              p.qualificationStatus === QualificationStatusEnum.QUALIFIED &&
              p.taxYear < 2022,
          );
          question.answerIDs = showERC
            ? question.answerIDs
            : question.answerIDs.filter((a) => a.id !== ERC_ANSWER_ID);
        }

        switch (question.id) {
          case GraphCmsQuestionIdEnum.SUBSIDIARY_QRE_PERCENTAGE:
            return renderSubsidiaryQreQuestion({ question, subGroupName });

          case GraphCmsQuestionIdEnum.ACQUISITION_QRE_PERCENTAGE:
            return renderAcquisitionQreQuestion({ question, subGroupName });

          case CompanyDetailsGraphCmsQuestionIds.REVIEW_QUALIFICATION_QUESTIONS:
            return renderQualificationQuestionsReviewComponent(
              question,
              subGroupName,
            );

          case CompanyDetailsGraphCmsQuestionIds.TAX_INFO_FILE_UPLOAD:
            return renderTaxReturnUploadQuestion(question, subGroupName);

          case CompanyDetailsGraphCmsQuestionIds.OTHER_CREDITS_CLAIMED_AMOUNT:
            return (
              <ExpandableSurveyQuestion
                questionProps={question}
                helpDrawerContent={<OtherCreditsHelpDrawer />}
                onQuestionPropsChange={(answers: SurveyAnswer[]) =>
                  addSurveyAnswers(question.id, subGroupName, answers)
                }
                key={question.id}
                onEditModeToggle={handleQuestionExpansion}
                saveAnswers={saveAnswers}
                chatbot={chatbot}
              />
            );

          case CompanyDetailsGraphCmsQuestionIds.NAICS_CODE:
            return (
              <ExpandableSurveyQuestion
                questionProps={question}
                helpLinkText='Where to find this information'
                onHelpLinkClick='https://www.census.gov/naics/?58967?yearbck=2017'
                onQuestionPropsChange={(answers: SurveyAnswer[]) =>
                  addSurveyAnswers(question.id, subGroupName, answers)
                }
                key={question.id}
                onEditModeToggle={handleQuestionExpansion}
                saveAnswers={saveAnswers}
                chatbot={chatbot}
              />
            );

          case CompanyDetailsGraphCmsQuestionIds.GA_RD_GROSS_RECIEPTS:
            return (
              <ExpandableSurveyQuestion
                questionProps={question}
                helpDrawerContent={
                  <GeorgiaRdGrossReceiptsHelpDrawer program={program} />
                }
                onQuestionPropsChange={(answers: SurveyAnswer[]) =>
                  addSurveyAnswers(question.id, subGroupName, answers)
                }
                key={question.id}
                onEditModeToggle={handleQuestionExpansion}
                saveAnswers={saveAnswers}
                chatbot={chatbot}
              />
            );

          default:
            return (
              <>
                <ExpandableSurveyQuestion
                  questionProps={question}
                  onQuestionPropsChange={(answers: SurveyAnswer[]) =>
                    addSurveyAnswers(question.id, subGroupName, answers)
                  }
                  key={question.id}
                  customAnswerSubtitle={undefined}
                  onEditModeToggle={handleQuestionExpansion}
                  saveAnswers={saveAnswers}
                  chatbot={chatbot}
                />
                {(doesNotRetainResearchRights || hasNoFinancialLossRisk) && (
                  <FileUpload
                    previouslyUploadedFiles={grantSpendingFiles}
                    allowFilePassword={false}
                    label='Upload Document'
                    title={'Please upload a document containing:'}
                    subtitle={`The details of how funded costs were spent on R&D,
                          like employees&rsquo wages, contractor wages, cloud
                          computing (e.g. AWS, Azure, Google Cloud) costs,
                          supplies costs that you used for R&D.
                          We will need to exclude employees&rsquo wages and
                          other R&D expenses that were paid out with the funded
                          research.`}
                    onFileAdded={onFileAdded(GRANT_SPENDING_DETIALS)}
                    onPasswordProtectedError={handlePasswordProtectedError}
                    onFileCancelled={handleFileRemoval}
                    onFileRemoved={handleFileRemoval}
                  />
                )}
                {willUploadGrantContract && (
                  <FileUpload
                    previouslyUploadedFiles={grantContractFiles}
                    allowFilePassword={false}
                    label='Upload Document'
                    title={'Upload Grant Contract'}
                    subtitle={
                      'If you are not sure about the above questions, you can provide your grant contract for our review.  We will reach out if we have any questions.'
                    }
                    onFileAdded={onFileAdded(GRANT_CONTRACT)}
                    onPasswordProtectedError={handlePasswordProtectedError}
                    onFileCancelled={handleFileRemoval}
                    onFileRemoved={handleFileRemoval}
                  />
                )}
              </>
            );
        }
      };

      const hasTaxFormUploadQuestion: boolean = Object.keys(
        questionsToRender,
      ).some((subgroup) =>
        questionsToRender[subgroup].find(
          (question) =>
            question.id ===
            CompanyDetailsGraphCmsQuestionIds.TAX_INFO_FILE_UPLOAD,
        ),
      );
      const isTaxFormUploadQuestionBlocking =
        hasTaxFormUploadQuestion && taxFormUploadEditMode;

      const canContinue =
        (isSurveyComplete || !isTaxFormUploadQuestionBlocking) &&
        expandedQuestionIds.size === 0;

      useEffect(() => {
        const answeredNo4PartTest =
          surveyAnswers[GraphCmsQuestionIdEnum.DO_ALL_STATEMENTS_APPLY] ===
          GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.DO_ALL_STATEMENTS_APPLY
          ].NO;

        const answeredNoToOwnAnyIP =
          surveyAnswers[
            GraphCmsQuestionIdEnum.PERFORM_RD_ACTIVITIES_UNDER_CONTRACT
          ] ===
          GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.PERFORM_RD_ACTIVITIES_UNDER_CONTRACT
          ].BUILD_ONLY_FOR_CLIENTS;

        if (answeredNo4PartTest || answeredNoToOwnAnyIP) {
          setDisqualifyingQuestions(true);
        } else {
          setDisqualifyingQuestions(false);
        }
      }, [surveyAnswers]);

      useEffectOnce(async () => {
        // Function Definitions
        const toggleShowShareAssessment = () => {
          if (featureFlags.showShareAssessment) {
            unifiedTaxCredits.setShareAssessmentOnNext(onNext);
          }
        };

        const setYeaPrefill = async () => {
          await client.SetYeaPrefill();
          await app.common.companyStore.refreshCurrentCompany();
          setReInitCompanyQualQuestions(
            (reInitCompanyQualQuestions) => reInitCompanyQualQuestions + 1,
          );
        };

        const updateProgramStage = async () => {
          if (
            program &&
            program.stage !== ProgramStageEnum.EXPENSE_CLASSIFICATION &&
            program.stage !== ProgramStageEnum.MS_REVIEW &&
            program.stage !== ProgramStageEnum.DISQUALIFIED
          ) {
            await surveyFlow.updateProgramStageStatus(
              ProgramNameEnum.FED_RD_TAX,
              taxYear,
              ProgramStageEnum.EXPENSE_CLASSIFICATION,
              QualificationStatusEnum.QUALIFICATION_IN_PROGRESS,
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS,
            );
          }
        };

        const fetchCompanyDocuments = async () => {
          // Load company documents for Tax Return and QRE question suppression
          const res = companyStore.accessToken
            ? await client.GetCompanyDocumentsPublic(companyStore.accessToken, {
                source: 'client',
              })
            : await client.GetCompanyDocuments({ source: 'client' });
          setDocuments(res.data?.documents || []);
        };

        // Function Calls
        toggleShowShareAssessment();
        await setYeaPrefill();
        await updateProgramStage();
        await fetchCompanyDocuments();
      });

      useEffect(() => {
        const qualificationQuestions =
          companyStore.company.qualificationQuestionsByYear?.[taxYear];

        const subsidiariesPerformRD =
          qualificationQuestions?.[
            GraphCmsQuestionIdEnum.SUBSIDIARIES_PERFORM_RD
          ] ===
          GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.SUBSIDIARIES_PERFORM_RD
          ].YES;

        const establishedASubsidiary =
          qualificationQuestions?.[
            GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES
          ] ===
          GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES
          ].YES_ESTABLISHED_SUBSIDIARY;

        setSubsidiariesPerformRD(
          subsidiariesPerformRD && establishedASubsidiary,
        );

        const acquireesPerformRD =
          qualificationQuestions?.[
            GraphCmsQuestionIdEnum.ACQUIRIES_PERFORM_RD
          ] ===
          GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.ACQUIRIES_PERFORM_RD
          ].YES;

        const acquiredCompany =
          qualificationQuestions?.[
            GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES
          ] ===
          GraphCmsQuestionIdToAnswers[
            GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES
          ].YES_FULL_ACQUISITION;

        setAcquireesPerformRD(acquireesPerformRD && acquiredCompany);
      }, [companyStore.company.qualificationQuestionsByYear, taxYear]);

      const surveyButtonEnabled = hasConfirmedAnswers && canContinue;

      return (
        <div className={classes.container}>
          <div className={classes.mainContent}>
            <SurveyFlowContainer
              title='R&D Expenses | Company Details'
              onContinue={onContinue}
              isDisabled={!surveyButtonEnabled}
              isLoading={surveyFlow.surveyContinueLoading}
              continueText={continueText}
              currentPage={Page.expenseClassificationCompanyDetails}
              onBack={companyStore.accessToken ? undefined : onBack}
              onSkip={() =>
                surveyFlow.skipSurveyStep(
                  taxYear,
                  program?.id ?? -1,
                  ProgramNameEnum.FED_RD_TAX,
                  onNext,
                  true,
                )
              }
            >
              <Loading loading={loading && isLoading}>
                <PrefillAlert />
                {showDqModal && (
                  <QualificationStatusModal
                    showModal={surveyFlow.showQualificationStatusModal}
                    modalOnClick={async () => {
                      await handleOnDisqualified();
                    }}
                    programName={ProgramNameEnum.FED_RD_TAX}
                  />
                )}
                {Object.keys(questionsToRender).map((subGroupName) => {
                  const subGroup = getSubGroupRenderData(subGroupName);
                  let subGroupTitle: SubGroupTitle = {};
                  if (subGroupName !== 'review') {
                    subGroupTitle = getTitleBySubGroup(subGroupName, subGroup);
                  }

                  const alert = getAlertBySubGroup(
                    subGroupName as SubGroupNameEnum,
                  );

                  return (
                    <React.Fragment key={`subgroup-${subGroupName}`}>
                      {subGroupTitle.title && (
                        <Text
                          text={subGroupTitle?.title}
                          size={18}
                          variant='medium'
                        />
                      )}
                      {subGroupTitle.subtitle && (
                        <Text
                          paddingBottom={alert ? 16 : 4}
                          text={subGroupTitle.subtitle}
                        />
                      )}
                      {alert}

                      {questionsToRender[subGroupName]
                        // TAX_INFO_MANUAL is asked as part of another question, so don't ask it again
                        .filter(
                          (question) =>
                            question.id !==
                            CompanyDetailsGraphCmsQuestionIds.TAX_INFO_MANUAL,
                        )
                        .map((question) => {
                          return (
                            <React.Fragment key={question.id}>
                              <Content
                                flex
                                flexDirection='column'
                                gap={32}
                                paddingTop={16}
                                paddingBottom={16}
                                paddingLeftRight={0}
                              >
                                {taxYear !== 0 && (
                                  <Delay
                                    waitBeforeShow={
                                      surveyAnswers[question.id] ? 450 : 900
                                    }
                                  >
                                    {renderQuestionContent(
                                      question,
                                      subGroupName,
                                    )}
                                  </Delay>
                                )}
                              </Content>
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
                <Divider />
                {canContinue && (
                  <SurveyAttestation
                    checked={hasConfirmedAnswers}
                    onAttestation={() =>
                      setHasConfirmedAnswers(!hasConfirmedAnswers)
                    }
                  />
                )}
              </Loading>
            </SurveyFlowContainer>
          </div>
        </div>
      );
    },
  );
